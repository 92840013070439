import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentService } from '@services/index';
import { environment } from 'src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements AfterViewInit {
  public room: string;
  public companyName;
  public companyLongName;
  public loader = true;
  public frontendEnabled = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,
    private current: CurrentService) { }

  ngOnInit() {

    this.loadMetaData();
    this.companyName = environment.companyName;
    this.companyLongName = environment.companyLongName;
    this.frontendEnabled = environment.frontendEnabled;
    const currentRoute = this.router.url;

    if (currentRoute === '/') {
      if (environment.frontendEnabled) {
        this.current.redirectToRoomIfAuthenticated();
      } else {
        if (environment.customHomepageURL) {
          window.location.href = environment.customHomepageURL;
        } else {
          this.router.navigate(['/signin']);
        }
      }
    }
  }

  loadMetaData() {
    const title = 'Free, Easy Video Chat | No Tech Skills Needed | OneClick Chat';
    const description = 'Accessible video chat designed for audiences ages 55+. Easily connect with friends and family over video chat.';

    this.titleService.setTitle(title);
    this.metaTagService.updateTag(
      { name: 'description', content: description }
    );
  }

  ngAfterViewInit() {
    import('src/assets/js/jquery.main');

    if (window['initUIEffects']) {
      window['initUIEffects']();
    }
  }

  continue() {
    this.router.navigate(['/signup']);
  }
}
