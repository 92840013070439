<header class="header" id="header">
    <nav class="navbar navbar-expand-md navbar-dark" [ngStyle]="{'background-color': backgroundColor}">
      <div class="container-fluid">
        <a class="navbar-brand text-white" routerLink="/">
          <img *ngIf="logoDark !== ''" src="{{logoDark}}" class="img-logo">
          <span *ngIf="logoDark === ''">
            {{companyName}}
          </span>
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  </header>

  <div id="sidebarMenu" class="sidebar sidebar-fixed sidebar-components ps">
    <div class="sidebar-body">
        <ul class="sidebar-nav">
            <li class="nav-label mg-b-15">MANAGE</li>
            <li class="nav-item"><a routerLink="/admin/manage-rooms" class="nav-link" [routerLinkActive]="['active']">
              <img class="icon-nav" src="../../../assets/icons/icons8-chat.svg">
              Rooms</a></li>
            <li class="nav-item"><a routerLink="/admin/manage-events" class="nav-link" [routerLinkActive]="['active']"> <img  class="icon-nav" src="../../../assets/icons/icons8-planner.svg"> Events</a></li>

            <li class="nav-item"><a routerLink="/admin/manage-feedback" class="nav-link" [routerLinkActive]="['active']">
              <img  class="icon-nav" src="../../../assets/icons/icons8-comments.svg">
              Feedback</a></li>

              <li class="nav-item"><a routerLink="/admin/manage-banned" class="nav-link" [routerLinkActive]="['active']">
                <img  class="icon-nav" src="../../../assets/icons/icons8-comments.svg">
                Banned</a></li>
  

            <!--  <li class="nav-item"><a routerLink="/admin/manage-account" class="nav-link" [routerLinkActive]="['active']"><svg xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-grid">
                        <rect x="3" y="3" width="7" height="7"></rect>
                        <rect x="14" y="3" width="7" height="7"></rect>
                        <rect x="14" y="14" width="7" height="7"></rect>
                        <rect x="3" y="14" width="7" height="7"></rect>
                    </svg> Account</a></li>

            <li class="nav-item"><a routerLink="/admin/manage-faq" class="nav-link"><svg xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-grid">
                        <rect x="3" y="3" width="7" height="7"></rect>
                        <rect x="14" y="3" width="7" height="7"></rect>
                        <rect x="14" y="14" width="7" height="7"></rect>
                        <rect x="3" y="14" width="7" height="7"></rect>
                    </svg> FAQ</a></li> -->

        </ul>
    </div><!-- sidebar-body -->
    <div class="ps__rail-x" style="left: 0px; top: 0px;">
        <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
    </div>
    <div class="ps__rail-y" style="top: 0px; height: 538px; right: 0px;">
        <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
    </div>
</div>