import { Component, OnInit } from '@angular/core';
import { CMSApiService } from '@services/api/cms-api.service';
import { GlobalService } from '@services/global.service';

@Component({
  selector: 'app-helpev',
  templateUrl: './helpev.component.html',
  styleUrls: ['./helpev.component.css']
})
export class HelpevComponent implements OnInit {

  public checking = false;
  public showResultMessage = false;
  public resultMessage = '';
  public testResults;

  content;
  contentVideo;
  contentAudio;
  contentOther;
  contentRSVP;
  contentJoining;
  isLoading = true;

  constructor(
    private globalService: GlobalService,
    private cms: CMSApiService
  ) { }

  ngOnInit() {
    this.load();
  }

  load() {
    // tslint:disable-next-line: deprecation
    this.cms.getToken().subscribe((retToken) => {
      this.globalService.cmsToken = retToken.access_token;
      // tslint:disable-next-line: deprecation
      this.cms.getHelpEvents().subscribe((ret) => {
        this.content = ret.items;

        this.contentVideo = ret.items.filter(d => d.data.type.iv[0] === 'video');
        this.contentAudio = ret.items.filter(d => d.data.type.iv[0] === 'audio');
        this.contentOther = ret.items.filter(d => d.data.type.iv[0] === 'other');
        this.contentJoining = ret.items.filter(d => d.data.type.iv[0] === 'joining');
        this.contentRSVP = ret.items.filter(d => d.data.type.iv[0] === 'rsvp');

        console.log(this.contentVideo.length);
        console.log(this.contentAudio.length);

        this.isLoading = false;
      });
    });
  }


}
