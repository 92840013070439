import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService, NotificationService, MixpanelService} from '@services/index';
import {OpentokService} from 'src/app/services/opentok.service';

@Injectable({ providedIn: 'root' })
export class PublisherService {
  public session: OT.Session;
  public publisher: OT.Publisher;
  public screenPublisher: OT.Publisher;
  public element: HTMLElement | string;
  public properties: OT.PublisherProperties;
  public screenShareProperties: OT.PublisherProperties;

  public isPublishing$ = new BehaviorSubject(false);
  public isAudioOn$ = new BehaviorSubject(true);
  public isVideoOn$ = new BehaviorSubject(true);
  public isSharingScreen$ = new BehaviorSubject(false);
  public active = true;

  constructor(private opentokService: OpentokService,
    private api: ApiService,
    private mixpanel: MixpanelService,
    private notification: NotificationService,
  ) {
    this.setProperties({
      width: '100%',
      height: '100%',
      insertMode: 'append',
      showControls: false,
      fitMode: 'cover',
    });

  }

  public destroy() {
    if (this.publisher) {
      this.publisher.destroy();
    }
  }

  public setAudioSource(deviceId: string) {
    this.publisher.setAudioSource(deviceId).then((m) => {
      this.notification.success('Device settings saved');
    }).catch((err) => {
      this.notification.error(err);
    });
  }

  // public setVideoSource(deviceId: string) {
  //   this.publisher.se(deviceId).then((m) => {
  //     this.notification.success('Device settings saved');
  //   }).catch((err) => {
  //     this.notification.error(err);
  //   });
  // }

  public getAudioSource() {
    return this.publisher.getAudioSource();
  }

  public cycleVideo() {
    return this.publisher.cycleVideo();
  }

  public initPublisher(
    session: OT.Session,
    targetElement?: HTMLElement | string,
    properties?: OT.PublisherProperties
  ) {
    this.session = session;
    this.element = targetElement;
    this.setProperties(properties);
    this.updatePublisher();
  }

  public setProperties(properties: Partial<OT.PublisherProperties>) {
    this.properties = { ...this.properties, ...properties };
    this.screenShareProperties = { ...this.screenShareProperties, ...properties };
  }

  public updatePublisher() {
    const previous = this.publisher;
    const opentok = this.opentokService.getOT();

    opentok.getDevices((err, devices) => {
      if (localStorage.getItem('videoDevice')) {
        let isExist = devices.find((device) => device.kind === 'videoInput' && device.deviceId === localStorage.getItem('videoDevice'));
        if (isExist) {
          this.properties.videoSource = localStorage.getItem('videoDevice');
        }
      }
      if (localStorage.getItem('audioDevice')) {
        let isExist = devices.find((device) => device.kind === 'audioInput' && device.deviceId === localStorage.getItem('audioDevice'));
        if (isExist) {
          this.properties.audioSource = localStorage.getItem('audioDevice');
        }
      }
      this.publisher = opentok.initPublisher(
        this.element,
        this.properties,
        (error) => {
          if (previous) {
            previous.destroy();
          }
        }
      );
      this.publishOnConnected();

    });


  }

  public updatePublisherScreenshare(currentUserId: string, roomName: string) {
    const previous = this.publisher;
    const opentok = this.opentokService.getOT();
    const session = this.session;

    this.screenPublisher = opentok.initPublisher(
      'screen-preview',
      this.screenShareProperties,
      (error) => {
        console.log(error);
        if (error && error.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
          // previous.destroy();
          this.isSharingScreen$.next(false);
          this.notification.error('Screen sharing Cancelled.');
          // this.api.room.update(roomName, { screenSharingId: '' });
        } else if (error) {
          previous.destroy();
          this.isSharingScreen$.next(false);
          // this.api.room.update(roomName, { screenSharingId: '' });
        } else {
          if (session) {
            this.api.room.update(roomName, { screenSharingId: currentUserId });
            this.isSharingScreen$.next(true);
            session.publish(this.screenPublisher, (error: OT.OTError) => {
              error
                ? this.opentokService.handleError(error)
                : this.isPublishing$.next(true);
            });
          }
        }
      }
    );

    // The user clicked stop.
    this.screenPublisher.on('mediaStopped', () => this.stopSharing(sessionStorage.getItem('room-name')));
    this.screenPublisher.on('streamDestroyed', (event) => {
      if (
        event.reason === 'mediaStopped' ||
        event.reason === 'forceUnpublished'
      ) {
        this.stopSharing(sessionStorage.getItem('room-name'));
      }
    });
  }

  public publishOnConnected() {
    const session = this.session;
    if (session) {
      session['isConnected']() && this.publish();
      session.once('sessionConnected', () => this.publish());
    }
  }

  public publish() {
    this.session.publish(this.publisher, (error: OT.OTError) => {
      error
        ? this.opentokService.handleError(error)
        : this.isPublishing$.next(true);
    });
  }

  public toggleAudio() {
    this.isAudioOn$.next(!this.isAudioOn$.value);
    this.publisher.publishAudio(this.isAudioOn$.value);
  }

  public toggleVideo() {
    this.isVideoOn$.next(!this.isVideoOn$.value);
    this.publisher.publishVideo(this.isVideoOn$.value);
  }
  public disconnectSession() {
    if (this.session) {
      this.session.disconnect();
    }
  }

  public startSharing(currentUserId: string, roomName: string) {
    this.screenShareProperties.videoSource = 'screen';
    this.screenShareProperties.audioSource = null;
    this.updatePublisherScreenshare(currentUserId, roomName);
    this.isSharingScreen$.next(true);
    this.mixpanel.track('Start screen share', {
      room: roomName,
    });
  }

  public stopSharing(roomName: string) {
    this.screenPublisher.destroy();
    this.isSharingScreen$.next(false);
    // this.api.room.update(roomName, { screenSharingId: '' });
    this.mixpanel.track('Stop screen share', {
      room: roomName,
    });
  }

  public sendSignal(data: any) {
    this.session.signal(data, (m) => {
      console.log('Signal sent');
    });
  }

}
