import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { ApiService } from './api/api.service';
import { CurrentService } from './current.service';
import { MixpanelService } from './mixpanel.service';
import { User } from '@models/index';
import { NotificationService } from './notification.service';
import LogRocket from 'logrocket';
import { environment } from 'src/environments/environment';

const ErrorMessages = {
  AlreadyExists: 'Room or email address is already exists',
  IncorrectEmail: 'Email address is incorrect',
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  public form = new FormGroup({
    room: new FormControl(),
    email: new FormControl(),
    name: new FormControl(),
    code: new FormControl(),
  });

  public error: string = null;
  public loading = false;
  public roomExist = -1;
  public roomCheckLoader = false;
  public codeSent = false;

  constructor(
    private api: ApiService,
    private router: Router,
    private current: CurrentService,
    private mixpanel: MixpanelService,
    private notification: NotificationService
  ) { }

  public signup(form) {
    this.error = null;
    this.loading = true;
    this.codeSent = false;

    this.api.user.signup(form).subscribe(
      (success) => {
        this.loading = false;
        this.codeSent = success;
        this.mixpanel.trackWithoutUser('Sign up', {
          email: form.email,
        });
        this.mixpanel.trackWithoutUser('Room created', {
          room: form.room,
        });
      },
      (error) => {
        this.notification.error("Your email is already registered.");
        this.loading = false;
        // this.current.redirectToSignIn(form.email);
      }
    );
  }

  public signin(form) {
    this.current.clear();
    this.error = null;
    this.loading = true;
    this.codeSent = false;

    this.api.user.signin(form).subscribe(
      (success) => {
        this.loading = false;
        this.codeSent = success;
        if (environment.hasLogrocket) {
          LogRocket.identify(form.email);
        }
      },
      (error) => this.handleError(error)
    );
  }

  public checkRoom(form) {
    this.roomExist = -1;
    this.roomCheckLoader = true;
    let v = form.room.trim();
    v = form.room.replace(/\s/g, "-");
    this.form.get('room').patchValue(v)

    this.api.room.exists(v).subscribe((m: any) => {
      m ? this.roomExist = 1 : this.roomExist = 0;
      this.roomCheckLoader = false;

    }, (err) => {
      this.roomExist = 0;
      console.log(err);
      this.roomCheckLoader = false;
      this.notification.error("Room name already exists");
    })

  }

  public confirm(form) {
    this.loading = true;
    this.api.user
      .confirm(form)
      .then((token: any) => {
        if (token.errorMessage) {
          this.error = token.errorMessage;
          this.loading = false;
        } else {
          this.current.token = token.value;
          this.current.fetchUser$.subscribe(
            (user: User) => {
              this.loading = false;

              if (!user) {
                this.error = ErrorMessages.AlreadyExists;
              }

              this.mixpanel.init(user.id, user);

              this.current.redirectToOwnedRoom(user);
            },
            (error) => {
              this.loading = false;
              this.handleError(error)
            }
          );
        }
      });
  }

  public signOut() {
    this.current.clear();
  }

  private handleError(error: any) {
    console.log(error);

    this.error = error || ErrorMessages.AlreadyExists;
    this.loading = false;
  }
}
