<!-- <div  class="toast-wrapper pos-absolute t-10 r-10">
  <div [id]="item.id" class="toast {{item.type}}" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      <h6 class="tx-inverse tx-14 mg-b-0 mg-r-auto">{{ item.title || 'Notification' }}</h6>
      <small *ngIf="item.date">{{ item.date }}</small>
      <button type="button" class="ml-2 mb-1 close tx-normal" data-dismiss="toast" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="toast-body">
      {{ item.message }} 
    </div>
  </div>
</div> -->
<ng-container *ngFor="let item of list; let i = index">
  <ngb-toast [header]="item.title || 'Notification!'" class="toast-message bg-{{item.type}} text-light" (hide)="removeToast(i)"[autohide]="true" [delay]="6000">
    {{ item.message }} 
  </ngb-toast>
</ng-container>