<div id="wrapper">
    <app-admin-header></app-admin-header>


    <div *ngIf="isAdmin === 0" class="admin-mode-container">
        <div class="row mg-b-10 mg-t-10">
            <div class="col-9">
                Restricted access
            </div>
        </div>
    </div>

    <div *ngIf="isAdmin === 1" class="admin-mode-container">
        <div *ngIf="showDetails">
            <div class="">
                <div class="row mg-b-10">
                    <div class="col-4">
                        <div class="row">
                            <div class="col-12 mb-4">
                                <h4 id="section1">Room: {{selectedRoom.roomName}}</h4>
                            </div>
                            <div class="col-5">
                                <div class="rating-box">{{selectedRoom.average.toFixed(2)}} / 5.0 </div>
                            </div>
                            <div class="col-7 text-center">
                                <h4>{{feedbackDetails?.length || 0}}</h4>
                                Total Ratings
                            </div>
                        </div>
                        <div *ngIf="selectedRoom">
                            <div class="row mt-4" *ngFor="let detail of selectedRoom.stats">
                                <div class="col-9">

                                    <div class="float-left progress-number" style="padding-right: 15px;">
                                        {{detail.value.toFixed(2)}} % </div>
                                    <div class="float-right" style="padding-left: 15px;">
                                        <star-rating class="star-rate" [value]="detail.label" [totalstars]="5"
                                            [checkedcolor]="'#ea2b65'" readonly="true" [uncheckedcolor]="'gray'"
                                            [size]="'15px'">
                                        </star-rating>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar"
                                            [ngStyle]="{'width': detail.value + 'px'}" aria-valuemin="0"
                                            aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="progress-text float-left">
                                        {{detail.total}} Ratings
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>
                    <div class="col-8">
                        <div style="margin-left: 30px;">
                            <div class="row">
                                <div class="col-6">
                                    <h4 class="mb-4 pull-left" id="section1">Recent Feedback</h4>
                                </div>
                                <div class="col-6">
                                    <button (click)="showDetails = false;" class="btn btn-primary float-right">Back to
                                        Feedback</button>
                                </div>
                            </div>
                            <div class="feedback-box" *ngFor="let answer of allAnswers">
                                <div class="row">
                                    <div class="col-4">
                                        <star-rating class="star-rate" [value]="answer.rating" [totalstars]="5"
                                            [checkedcolor]="'#ea2b65'" readonly="true" [uncheckedcolor]="'gray'"
                                            [size]="'15px'">
                                        </star-rating>
                                        <strong>{{answer.username}}</strong>
                                        <div>{{answer.createdOn | date}}</div>
                                    </div>
                                    <div class="col-8">
                                        <div><strong> {{answer.question.value}} </strong></div>
                                        <div>
                                            {{answer.value}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="table-responsive mt-5" *ngIf="allAnswers && allAnswers.length < 1">
                                <p>No Feedbacks found.</p>
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </div>

        <div *ngIf="!showDetails">
            <div class="row mg-b-10">
                <div class="col-6">
                    
                </div>
                <div class="col-6">
                    <a *ngIf="allFeedback && allFeedback.length > 0" href="https://oclabapi.azurewebsites.net/management/download-feedback-details" target="_blank"
                        class="btn btn-sm btn-primary float-right ml-4"> <i class="fa fa-download"></i> Download xls</a>
                </div>
            </div>
            <div class="table-responsive" *ngIf="allFeedback && allFeedback.length > 0">
                <table class="table table-striped mg-b-0">
                    <thead>
                        <tr>
                            <th scope="col">Room name</th>
                            <th scope="col">Rating</th>
                            <th></th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let r of allFeedback">
                            <th scope="row">{{r.roomName}}</th>
                            <th scope="row">
                                <star-rating class="star-rate" readonly="true" [value]="r.average.toFixed(2)"
                                    [totalstars]="5" [checkedcolor]="'#ea2b65'" [uncheckedcolor]="'white'" size="15px">
                                </star-rating>
                            </th>
                            <th scope="row">
                                {{r.average.toFixed(2)}}
                            </th>

                            <th scope="row">
                                <button class="btn btn-link" (click)="showFeedbackDetails(r)">Details</button>
                            </th>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div class="table-responsive mt-5" *ngIf="allFeedback && allFeedback.length < 1">
                <p>No Feedbacks found.</p>
            </div>

            <div class="table-responsive mt-5" *ngIf="loading">
                <p>Loading...</p>
            </div>
        </div>
    </div>




</div>