import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogComponent } from './dialog/dialog.component';
import { RoomListComponent } from './room-list/room-list.component';
import { ComponentsModule } from '../components/components.module';

@NgModule({
  declarations: [DialogComponent, RoomListComponent],
  exports: [DialogComponent, RoomListComponent],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
  ],
})
export class DialogsModule {}
