<div class="dialog" [hidden]="!visible">
  <header class="dialog-header">
    <h5 class="float-left">{{ header }}</h5>

    <div class="float-right ht-30">
      <ng-content select="[header]"></ng-content>
      <button type="button" class="close mg-l-10" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </header>
  <div class="clearfix"></div>
  <div class="overflow-wrapper">
    <ng-content></ng-content>
  </div>
</div>
