<app-header></app-header>

<div class="content content-auth">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <div class="row justify-content-center align-items-center" style="padding:0px 15px; background: #ddd;" *ngIf="loadingVideo">
          <div class="col-12">
            <div class="stream-element publishing justify-content-center align-items-center"> </div>
          </div>
        </div>

        <div class="row">
          <div class="stream-element publishing">
            <video style="width: 100%;" #publisherDiv [src]="videoSrc"></video>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-xs-12">
       
        <div *ngIf="!isCameraInitalized" class="sign-wrapper">
          <div class="wd-300p">
            <h3 class="tx-color-01 mg-b-5">Getting Ready...</h3>
            <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
          </div>
        </div>

        <ng-container *ngIf="isCameraInitalized">
          <div *ngIf="auth as auth" class="sign-wrapper">
            <div class="header-adblocked" *ngIf="adBlockEnabled">
              Please disable ad blocker to continue
            </div>
            <form [formGroup]="signinform" class="wd-300p" (ngSubmit)="onSubmit()">
              <h3 class="tx-color-01 mg-b-5">Sign In</h3>
              <p class="tx-color-03 tx-16 mg-b-10">Welcome to {{companyLongName}}!<br />Please signin to continue.</p>
              <div class="tx-color-03 mg-b-10">
                <span *ngIf="auth.error" class="error">{{ auth.error }}</span>
                <span *ngIf="!auth.error && auth.loading && !auth.codeSent">Sending code..</span>
                <span *ngIf="!auth.error && auth.loading && auth.codeSent">Redirecting..</span>
                &nbsp;
                <span class="error" *ngIf="userExists">User already exists.</span>
              </div>
              <div class="form-group">
                <label>Email address</label>
                <input formControlName="email" type="email" class="form-control" [(ngModel)]="email"
                  placeholder="yourname@yourmail.com" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Please enter email</div>
                  <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                </div>
              </div>
              <div *ngIf="auth.codeSent" class="form-group">
                <div class="d-flex justify-content-between mg-b-5">
                  <label class="mg-b-0-f">Code</label>
                </div>
                <input formControlName="code" type="password" class="form-control" placeholder="Enter code" required
                  maxlength="6" />
                <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
                  <div *ngIf="f.code.errors.required">Code is required</div>
                </div>
              </div>
              <button *ngIf="!auth.codeSent" type="submit" class="btn btn-brand-02 btn-block tx-bold">
                <span *ngIf="auth.loading" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span>
                <span *ngIf="!auth.loading">CONTINUE</span>
              </button>
              <button *ngIf="auth.codeSent" type="submit" class="btn btn-brand-02 btn-block tx-bold">
                <span *ngIf="auth.loading" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span>
                <span *ngIf="!auth.loading">CONFIRM</span>
              </button>
              <div class="text-center" *ngIf="auth.codeSent">
                <button (click)="cancel()" type="button" class="btn btn-link">
                  <span>CANCEL</span>
                </button>
              </div>
              <div class="tx-13 mg-t-20 tx-center">
                Don't have an account? <a routerLink="/signup">Create an Account</a>
              </div>
            </form>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>