<div id="wrapper">
    <app-admin-header></app-admin-header>

    <div *ngIf="isAdmin === 0" class="admin-mode-container">
        <div class="row mg-b-10 mg-t-10">
            <div class="col-9">
                Restricted access
            </div>
        </div>
    </div>

    <div *ngIf="isAdmin === 1" class="admin-mode-container">
        <div class="table-responsive" *ngIf="allBanned && allBanned.length > 0">
            <table class="table table-striped mg-b-0">
                <thead>
                    <tr>
                        <th scope="col">User Name</th>
                        <th scope="col">Room Name</th>
                        <th scope="col">IP</th>
                        <th scope="col" class="text-center">Un Ban</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let r of allBanned">
                        <th scope="row">
                            <span *ngIf="r.username === 'null'">Anonymous</span>
                            <span *ngIf="r.username !== 'null'">{{r.username}}</span>
                        </th>
                        <th scope="row">{{r.roomName}}</th>
                        <th scope="row">{{r.ipAddress}}</th>
                       
                        <td class="text-center">
                            <button class="btn btn-link" (click)="unbanUser(r.id);">   Un Ban </button>
                            
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <div class="table-responsive mt-5" *ngIf="allBanned && allBanned.length < 1">
            <p>No users found.</p>
        </div>

        <div class="table-responsive mt-5" *ngIf="loading">
            <p>Loading...</p>
        </div>
    </div>

    <app-dialog name="addRoom" header="Add a room">
        <app-add-room (roomCreated)="roomSuccess($event)" [organizationId]="organization?.id"></app-add-room>
    </app-dialog>

</div>