<div class="participants">
 
  <div class="participants__group">

    <div class="row" *ngIf="contentAudio && contentAudio.length > 0">
      <div class="col-md-12">
        <div class="participants__group__header black mg-b-4 tx-18">Audio Issues</div>
      </div>
    </div>

    <accordion>
      <accordion-group heading="{{helpItem.data.title.iv}}" *ngFor="let helpItem of contentAudio">
        <p [innerHTML]="helpItem.data.value.iv"></p>
      </accordion-group>
    </accordion>
    
    <div class="row" *ngIf="contentVideo && contentVideo.length > 0">
      <div class="col-md-12">
        <div class="participants__group__header black mg-t-8  mg-b-4 tx-18">Video Issues</div>
      </div>
    </div>

    <accordion>
      <accordion-group heading="{{helpItem.data.title.iv}}" *ngFor="let helpItem of contentVideo">
        <p [innerHTML]="helpItem.data.value.iv"></p>
      </accordion-group>
    </accordion>

    <div class="row" *ngIf="contentJoining">
      <div class="col-md-12">
        <div class="participants__group__header black mg-t-8 mg-b-4 tx-18">Joining</div>
      </div>
    </div>

    <accordion>
      <accordion-group heading="{{helpItem.data.title.iv}}" *ngFor="let helpItem of contentJoining">
        <p [innerHTML]="helpItem.data.value.iv"></p>
      </accordion-group>
    </accordion>
    
    <div class="row" *ngIf="contentRSVP">
      <div class="col-md-12">
        <div class="participants__group__header black mg-t-8 mg-b-4 tx-18">RSVP</div>
      </div>
    </div>

    <accordion>
      <accordion-group heading="{{helpItem.data.title.iv}}" *ngFor="let helpItem of contentRSVP">
        <p [innerHTML]="helpItem.data.value.iv"></p>
      </accordion-group>
    </accordion>
    
    <div class="row">
      <div class="col-md-12">
        <div class="participants__group__header black mg-t-8 mg-b-4 tx-18">Other</div>
      </div>
    </div>

    <accordion>
      <accordion-group heading="{{helpItem.data.title.iv}}" *ngFor="let helpItem of contentOther">
        <p [innerHTML]="helpItem.data.value.iv"></p>
      </accordion-group>
    </accordion>

  </div>

</div>


