<header class="header" id="header">
  <nav class="navbar navbar-expand-md navbar-dark" [ngStyle]="{'background-color': backgroundColor}">
    <div class="container">
      <a class="navbar-brand text-white" routerLink="/">
        <img *ngIf="logoDark !== ''" src="{{logoDark}}" class="img-logo">
        <span *ngIf="logoDark === ''">
          {{companyName}}
        </span>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav navbar-right ml-auto">
          <li class="nav-item" *ngIf="showLogin"><a class="nav-link" routerLink="/signin">Login</a></li>
        </ul>
        <div class="my-2 my-md-0"><a class="btn btn-secondary my-2 my-sm-0" routerLink="/signup">Sign up Free</a></div>
      </div>
    </div>
  </nav>
</header>