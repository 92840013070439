import { Component, OnInit } from '@angular/core';
import { DialogService } from '../../dialogs/dialog.service';
import { ApiService } from '@services/index';
import { NotificationService } from '@services/index';

@Component({
  selector: 'app-manage-rooms',
  templateUrl: './manage-rooms.component.html',
  styleUrls: ['./manage-rooms.component.css']
})
export class ManageRoomsComponent implements OnInit {

  allRooms;
  organization;
  loading = false;
  allOrganization;
  isAdmin = -1;

  constructor(
    public dialog: DialogService,
    public api: ApiService,
    public notificationService: NotificationService,
  ) { }

  ngOnInit(): void {

    this.loading = true;
    this.api.management.checkAdmin().subscribe((r: any) => {
      this.loading = false;
      if (r === true) {
        this.isAdmin = 1;
      } else {
        this.isAdmin = 0;
      }
    }, (error) => {
      this.isAdmin = 0;
      this.notificationService.error("Error calling API");
    });

    this.getRoom();

  }

  getRoom() {
    this.loading = true;

    this.api.management.getRooms("").subscribe((r) => {
      this.allRooms = r;
      this.loading = false;
    });
  }

  roomSuccess(args) {
    this.getRoom();
    this.dialog.closeAll();
  }

  deleteRoom(roomId) {
    let r = confirm('Are you sure you want to delete?');
    if (r === true) {
      this.api.management.deleteRoom("1", roomId).subscribe((r: any) => {
        this.notificationService.success("Deleted.");
        this.getRoom();
      });
    }

  }

}
