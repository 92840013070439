import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-star',
  templateUrl: './star.component.html',
  styleUrls: ['./star.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarComponent implements OnInit {
  @Input() isFavorited: boolean = false;
  @Output() changed: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public favorite() {
    this.isFavorited = true;
    this.changed.emit(this.isFavorited);
  }

  public unfavorite() {
    this.isFavorited = false;
    this.changed.emit(this.isFavorited);
  }
}
