<app-header></app-header>

<div class="content content-auth">
  <div class="container">
    <div class="media align-items-stretch justify-content-center ht-100p">
      <div class="sign-wrapper mg-lg-r-50 mg-xl-r-60">
        <form [formGroup]="signinform" class="pd-t-20 wd-100p" (ngSubmit)="onSubmit()">
          <h4 class="tx-color-01 mg-b-5 tx-30 tx-bold">Sign Up for Free</h4>
          <p class="tx-color-03 tx-16 mg-b-10">
            Create your personal video chat room to meet<br>with friends and family.
          </p>
          <p class="tx-color-03 tx-14 mg-b-10">
            All we need is your name and your email address to<br>create your room.
          </p>

          <p class="tx-color-03 mg-b-10">
            <span *ngIf="auth.error" class="error">{{ auth.error }}</span>
            <span *ngIf="auth.loading && !auth.codeSent">Sending code..</span>
            &nbsp;
          </p>
          <div *ngIf="auth.loading && auth.codeSent">Redirecting..</div>

          <div class="form-group">
            <label>Name</label>
            <input formControlName="name" type="text" class="form-control" placeholder="Enter your First and Last Name" required />

            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">Required</div>
            </div>

          </div>

          <div class="form-group">
            <label>Email address</label>
            <input formControlName="email" type="email" class="form-control" 
              placeholder="Enter your email address" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Required</div>
              <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
          </div>


          <div class="form-group">
            <label>Room Name</label>
            <input formControlName="room" [disabled]="auth.roomCheckLoader" type="text" (ngModelChange)="roomNameUpdate.next($event)" class="form-control" placeholder="Enter a name for your meeting room" required />

            <div *ngIf="submitted && f.room.errors" class="invalid-feedback">
              <div *ngIf="f.room.errors.required">Required</div>
              <div *ngIf="f.room.errors.maxlength.actualLength > 20">Room name should not exceed 20 char.</div>
            </div>

            <span class="fa fa-check-circle text-success room-check" *ngIf="auth.roomExist === 1"></span>
            <span class="fa fa-times-circle text-danger room-check" *ngIf="auth.roomExist === 0"></span>
            <span class="ext-room-check text-left float-left mb-3" *ngIf="auth.roomExist === 0">
              <small>Room name already exists. If you own this room, <a routerLink="/signin"> sign in here </a></small>
            </span>
          </div>

          <div *ngIf="auth.codeSent" class="form-group">
            <div class="d-flex justify-content-between mg-b-5">
              <label class="mg-b-0-f">Email Verification Code</label>
            </div>
            <input formControlName="code" type="password" class="form-control" placeholder="Please enter the code we just emailed you." required maxlength="6" />
            <div class="mg-t-10 tx-11">If you didn't receive the confirmation email, check the spam folder.</div>
          </div>
          <!-- form-group -->

          <button id="btn_signup" *ngIf="!auth.codeSent"  [disabled]="auth.loading || auth.roomCheckLoader || auth.roomExist === 0" type="submit" class="btn btn-brand-02 btn-block tx-bold">
            <span *ngIf="auth.loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span *ngIf="!auth.loading">CONTINUE</span>
          </button>
          <button id="btn_confirm_code" *ngIf="auth.codeSent" [disabled]="auth.loading" type="submit" class="btn btn-brand-02 btn-block tx-bold">
            <span *ngIf="auth.loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span *ngIf="!auth.loading">CONFIRM</span>
          </button>

          <div class="tx-13 mg-t-20 tx-center">Already have an account? <a routerLink="/signin">Sign In</a></div>
        </form>
      </div>
      <!-- sign-wrapper -->
      <div class="media-body pd-y-30 pd-lg-x-50 pd-xl-x-60 align-items-center d-none d-lg-flex pos-relative">
        <div class="mx-lg-wd-500 mx-xl-wd-550">
          <img src="/assets/images/img16.png" class="img-fluid" alt="" />
        </div>
      </div>
      <!-- media-body -->
    </div>
    <!-- media -->
  </div>
  <!-- container -->
</div>