import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {

  showLogin = true;
  companyName;
  backgroundColor;
  logoDark;
  @Input() organization: any;

  constructor() { }

  ngOnInit(): void {
    this.companyName = environment.companyName;
    this.showLogin = window.location.href.indexOf('bounce') < 0;
    this.backgroundColor = environment.headerBackgroundColor;
    this.logoDark = environment.logoDark;
  }


}
