<header class="header" id="header">
  <nav class="navbar navbar-expand-md navbar-dark" [ngStyle]="{'background-color': backgroundColor}">
    <div class="container">
      <a class="navbar-brand text-white" routerLink="/">
        <img *ngIf="logoDark !== ''" src="{{logoDark}}" class="img-logo">
        <span *ngIf="logoDark === ''">
          {{companyName}}
        </span>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </nav>
</header>