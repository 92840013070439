import { environment } from './../../../environments/environment.prod.leap';
import { RoomApiService } from './../../services/api/room-api.service';
import { Component, OnInit } from '@angular/core';
import { StarRatingComponent } from 'ng-starrating';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RoomService } from './../../room/services/room.service';

import { ApiService, NotificationService } from '@services/index';
import { forkJoin } from 'rxjs';

import { CookieService } from 'ngx-cookie-service';
import { CurrentService, MixpanelService } from '@services/index';


@Component({
  selector: 'app-left',
  templateUrl: './left.component.html',
  styleUrls: ['./left.component.css']
})
export class LeftComponent implements OnInit {

  deviceInfo = null;
  loading = true;
  feedback = '';
  issueInformation = '';

  room = '';
  browser = '';
  browserVersion = '';
  os = '';
  osVersion = '';
  device = '';
  userAgent = '';
  questions;
  disabled = false;
  completed = false;
  networkSpeed;
  isCollectingData = false;
  frontendEnabled = false;

  constructor(
    private deviceService: DeviceDetectorService,
    private api: ApiService,
    public notificationService: NotificationService,
    public current: CurrentService,
    private cookie: CookieService,
    public roomService: RoomService,
    private mixpanel: MixpanelService,
    private currentService: CurrentService,
    private roomApi: RoomApiService,

  ) { }

  ngOnInit() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);

    this.frontendEnabled = environment.frontendEnabled;

    this.room = sessionStorage.getItem('room-name');
    this.browser = this.deviceInfo.browser;
    this.browserVersion = this.deviceInfo.browser_version;
    this.os = this.deviceInfo.os;
    this.osVersion = this.deviceInfo.os_version;
    this.device = this.deviceInfo.device;
    this.userAgent = this.deviceInfo.userAgent;
    console.log(this.deviceInfo);
    this.bandwidthTest();
    this.fetchQuestions();
    if (this.roomService.isRoomOwner) {
      this.api.room.update(sessionStorage.getItem('room-name'), { eventStarted: false })
    }
  }

  fetchQuestions() {
    this.loading = true;
    this.api.faq.getQuestions(sessionStorage.getItem("roomId")).subscribe((m) => {
      this.questions = m;
      this.questions.forEach(element => {
        element['answer'] = '';
        element['rating'] = 5;
      });
      this.loading = false;
    })
  }

  cancel() {

  }

  send() {

    let subscriptionArr = [];

    subscriptionArr.push(this.roomApi.feedback(this.room, this.feedback, this.issueInformation, this.browser, this.browserVersion,
      this.os, this.osVersion, this.device, this.userAgent, this.networkSpeed));

    this.questions.forEach(element => {
      subscriptionArr.push(
        this.api.faq.addAnswer(sessionStorage.getItem("roomId"), element.id, element.answer, element.rating.toString())
      );
    });

    this.disabled = true;
    forkJoin(subscriptionArr).subscribe((ret) => {
      this.completed = true;
    });

  }

  onRate($event, question: any) {
    const value = $event.starRating.value;
    question.rating = value;
  }

  public bandwidthTest() {
    this.isCollectingData = true;
    const imageAddr = 'https://oneclickres.blob.core.windows.net/pub/downloadable.png' + '?n=' + Math.random();
    let endTime;
    const download = new Image();
    download.src = imageAddr;

    let startTime = (new Date()).getTime();

    download.onload = () => {
      endTime = (new Date()).getTime();
      const downloadSize = 5616998;
      const duration = (endTime - startTime) / 1000; //Math.round()
      const bitsLoaded = downloadSize * 8;
      const speedBps = <any>(bitsLoaded / duration).toFixed(2);
      const speedKbps = <any>(speedBps / 1024).toFixed(2);
      this.networkSpeed = (speedKbps / 1024).toFixed(2);
      this.isCollectingData = false;
    }
  }

}
