import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CurrentService, GlobalService } from '@services/index';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private current: CurrentService, private global: GlobalService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const isCms = request.url.indexOf('cms.oneclick.chat') >= 0;
    if (isCms) {
      if (this.global.cmsToken) {
        request = request.clone({
          setHeaders: { Authorization: `Bearer ${this.global.cmsToken}` },
        });
      }
    } else {
      if (this.current.token) {
        request = request.clone({
          setHeaders: { Authorization: `Bearer ${this.current.token}` },
        });
      }
    }

    return next.handle(request);
  }
}
