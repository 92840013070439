import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { CurrentService } from './current.service';

@Injectable({
    providedIn: 'root'
})


export class MixpanelService {

    hasInit: any;
    constructor(
        public current: CurrentService,
    ) { }


    init(userToken: string, user:any): void {
        mixpanel.init("bd7767daa98bf430c007e9325e9cd3a3", {batch_requests: true});
        mixpanel.identify(userToken);
        this.hasInit = true;
        mixpanel.people.set({
            "$email": user.email,
            "name": user.name,
            "rooms": user.rooms,
        });
    }
    

    track(id: string, action: any = {}): void {
        if (!this.hasInit) {
            this.init(this.current.user.id, this.current.user);
        }
        mixpanel.track(id, action);

    }

    initWithoutToken(): void {
        mixpanel.init("bd7767daa98bf430c007e9325e9cd3a3", {batch_requests: true});
    }

    trackWithoutUser(id: string, action: any = {}): void {
        mixpanel.track(id, action);
    }
}