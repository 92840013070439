import { FoiComponent } from './foi/foi.component';
import { AsComponent } from './auth/as/as.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { IllinoisGuard } from './guards/illinois.guard';
import { SigninComponent } from './auth/signin/signin.component';
import { SignupComponent } from './auth/signup/signup.component';
import { BounceComponent } from './auth/bounce/bounce.component';
import { HomeComponent } from './pages/home/home.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { TeamsComponent } from './pages/teams/teams.component';
import { AboutComponent } from './pages/about/about.component';
import { PressComponent } from './pages/press/press.component';
import { FaqComponent } from './pages/faq/faq.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { LeftComponent } from './pages/left/left.component';
import { BannedComponent } from './pages/banned/banned.component';
import { LockedComponent } from './pages/locked/locked.component';
import { EjectedComponent } from './pages/ejected/ejected.component';
import { PermissionComponent } from './pages/permission/permission.component';
import { BlogComponent } from './pages/blog/blog.component';
import { PostComponent } from './pages/post/post.component';
import { EventsComponent } from './pages/events/events.component';
import { ManageRoomsComponent } from './admin/manage-rooms/manage-rooms.component';
import { ManageBannedComponent } from './admin/manage-banned/manage-banned.component';
import { ManageEventsComponent } from './admin/manage-events/manage-events.component';
import { ManageFeedbackComponent } from './admin/manage-feedback/manage-feedback.component';
import { ManageAccountComponent } from './admin/manage-account/manage-account.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'signin', component: SigninComponent },
  { path: 'signin/:msg', component: SigninComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'press', component: PressComponent },
  { path: 'about', component: AboutComponent },
  { path: 'bounce', component: BounceComponent },
  { path: 'as', component: AsComponent },
  { path: 'team', component: TeamsComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'post/:id', component: PostComponent },
  { path: 'notfound', component: NotfoundComponent },
  { path: 'left', component: LeftComponent },
  { path: 'permission', component: PermissionComponent },
  { path: 'banned', component: BannedComponent },
  { path: 'events', component: EventsComponent },
  { path: 'rsvp', component: EventsComponent },
  { path: 'ejected', component: EjectedComponent },
  { path: 'locked/:name', component: LockedComponent },
  { path: 'foi', component: FoiComponent },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'admin/manage-rooms', component: ManageRoomsComponent , canActivate: [IllinoisGuard]},
  { path: 'admin/manage-banned', component: ManageBannedComponent , canActivate: [IllinoisGuard]},
  { path: 'admin/manage-events', component: ManageEventsComponent , canActivate: [IllinoisGuard]},
  { path: 'admin/manage-feedback', component: ManageFeedbackComponent , canActivate: [IllinoisGuard]},
  { path: 'admin/manage-account', component: ManageAccountComponent , canActivate: [IllinoisGuard]},
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./room/room.module').then((m) => m.RoomModule),
  },
  { path: '**', redirectTo: '/notfound'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
