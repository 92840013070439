<header class="header">
  <div class="header__title">
    <div class="header__main">
      <div class="header__name">{{companyName}}</div>
      <div class="header__subname"></div>
    </div>
  </div>
  <div class="header__controls">
    <div></div>
    <div class="header__icons">
      <div (click)="dialog.toggle('room')">
        <img class="header__icon" src="/assets/icons/icons8-room.svg" />
        <span>ROOMS</span>
      </div>
      <div routerLink="/settings">
        <img class="header__icon" src="/assets/icons/icons8-settings.svg" />
        <span>SETTINGS</span>
      </div>
    </div>
    <div></div>
  </div>
</header>

<div class="main-content">
  <div class="uppercase">
    <ul class="list-group">
      <li class="list-group-item">Update account information <i class="fa fa-chevron-right"></i></li>
      <li class="list-group-item">Update payment details <i class="fa fa-chevron-right"></i></li>
      <li class="list-group-item" (click)="createRoom()">Create new room <i class="fa fa-chevron-right"></i></li>
    </ul>
    <div class="logout-link">
      <span routerLink="/logout" (click)="logout()">
        Log off
      </span>
    </div>
  </div>

  <app-dialog name="room" header="Rooms">
    <app-room-list *ngIf="dialog.isOpen('room')"></app-room-list>
  </app-dialog>

</div>
