import { Component, OnInit, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { Subscription } from "rxjs";
import { Notification } from '@models/index';

declare var jQuery: any;

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  public list: Notification[] = [];
  private subscription: Subscription;
  constructor(private notificationSvc: NotificationService) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ngOnInit(): void {
    this.subscription = this.notificationSvc.getObservable().subscribe(notification => {
      console.log(notification);
      this.list.push(notification);
    });
  }
  removeToast(index){
    this.list.splice(index, 1);
  }
}
