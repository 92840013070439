import { ApiService } from '@services/index';
import { Component, OnInit } from '@angular/core';
import { DialogService } from '../../dialogs/dialog.service';
import { NotificationService } from '@services/index';

@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.css']
})
export class ManageAccountComponent implements OnInit {

  constructor(
    public dialog: DialogService,
    public api: ApiService,
    public notificationService: NotificationService,
  ) { }
  model: any = {};
  ngOnInit(): void {
    this.api.management.getOrganization().subscribe((r: any) => {
      if (r && r.length > 0) {
        this.model = r[0];
      } else {
        this.notificationService.error("Please add an organization to continue.");
      }
    });
  }

  onSubmit() {
    this.api.management.addUserToOrganization(this.model.label, this.model.name).subscribe((r: any) => {
      if (r) {
        this.notificationService.success("Organization created");
      }
    }, (error) => {
      this.notificationService.error("Error creating Organization");
    });
  }
}
