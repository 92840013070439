<app-header-anon></app-header-anon>



<div class="content content-auth">
  <div class="container">
    <div class="row">
      
      <div class="col-sm-6 col-xs-12">
        <div class="row justify-content-center align-items-center" style="padding:0px 15px; background: #ddd;" *ngIf="loadingVideo">
          <div class="col-12">
            <div class="stream-element publishing justify-content-center align-items-center"> </div>
          </div>
        </div>

        <div class="row">
          <div class="stream-element publishing">
            <video style="width: 100%;" #publisherDiv [src]="videoSrc"></video>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-xs-12">
        <div *ngIf="!isCameraInitalized" class="sign-wrapper">
          <div>
            <h3 class="tx-color-01 mg-b-5">Getting Ready...</h3>
            <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
          </div>
        </div>
  
        <ng-container *ngIf="isCameraInitalized">
          <div class="sign-wrapper">
            <div class="header-adblocked" *ngIf="adBlockEnabled">
              Please disable ad blocker to continue
            </div>
            <div *ngIf="loading">
              <h4 class="tx-color-01 mg-b-5">Getting ready to Join</h4>
              <p class="tx-color-03 tx-16 mg-b-10">Please wait.</p>
            </div>
  
            <form [formGroup]="form" *ngIf="!loading">
              <h4 class="tx-color-01 mg-b-5">Enter Your Name to Join</h4>
              <p class="tx-color-03 tx-16 mg-b-10">Enter your email address to skip this step next time you join a
                meeting.</p>
  
              <div class="form-group">
                <input formControlName="name" type="text" required class="form-control" placeholder="Enter your name"
                  required />
              </div>
  
              <div class="form-group mg-t-16">
                <input formControlName="email" type="text" required class="form-control"
                  placeholder="Enter your email address (optional)" required />
              </div>
  
              <button type="submit" class="btn btn-brand-02 btn-block tx-bold"
                [disabled]="loading || !form.controls.name.value" (click)="updateName()" *ngIf="!isAnonymous">
                <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span *ngIf="!loading">JOIN MEETING</span>
              </button>
  
              <button type="submit" class="btn btn-brand-02 btn-block tx-bold" (click)="anonymousSignUp()"
                *ngIf="isAnonymous">
                <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span *ngIf="!loading">JOIN MEETING</span>
              </button>
  
              <span *ngIf="error" class="error mg-t-16 float">{{ error }}</span>
  
            </form>
  
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>