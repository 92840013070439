<div id="wrapper">
    <app-admin-header></app-admin-header>

    <div  class="admin-mode-container">
        <div class="row mg-b-10">
            <div class="col-9">
                <h4 id="section1" class="float-left">Your Organization</h4>
            </div>
            <div class="col-3">
            </div>
        </div>

        <div class="">
            <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>

                <div class="participants">
                    <div class="participants__group">
                        <div class="form col-md-12 align-self-end">
                            <div class="row">
                                <div class="col-5 mt-2 pd-0">
                                    <div class="participants__group__header black">Name</div>
                                    <input type="text" class="form-control" name="name" [(ngModel)]="model.name"
                                        #name="ngModel" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }"
                                        required />
                                    <div *ngIf="f.submitted && name.invalid" class="invalid-feedback">
                                        <div *ngIf="name.errors.required">Name is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5 mt-2 pd-0">
                                    <div class="participants__group__header black">Label</div>
                                    <input type="text" class="form-control" name="label" [(ngModel)]="model.label"
                                        #label="ngModel" [ngClass]="{ 'is-invalid': f.submitted && label.invalid }"
                                        required />
                                    <div *ngIf="f.submitted && label.invalid" class="invalid-feedback">
                                        <div *ngIf="label.errors.required">Name is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 mt-2 pd-0">
                                    <button type="submit" class="btn btn-primary tx-bold">
                                        Save
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>

</div>