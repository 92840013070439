import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { HeaderComponent } from './header/header.component';
import { HeaderAnonComponent } from './header-anon/header-anon.component';
import { BounceComponent } from './bounce/bounce.component';
import { CameraInitializerComponent } from '../camera-initializer/camera-initializer.component';

@NgModule({
  declarations: [
    SigninComponent,
    SignupComponent,
    HeaderComponent,
    HeaderAnonComponent,
    BounceComponent,
    CameraInitializerComponent,
  ],
  imports: [RouterModule, FormsModule, ReactiveFormsModule, CommonModule],
  providers: [CookieService],
})
export class AuthModule {}
