<form name="form" (ngSubmit)="f.form.valid && onSubmit(f)" #f="ngForm" novalidate>

    <div class="participants">
        <div class="participants__group">
            <div class="form col-md-12 align-self-end">
                <div class="row">
                    <div class="col-12 mt-2 pd-0">
                        <div class="participants__group__header black">Name</div>
                        <input type="text" class="form-control" name="yourName" (ngModelChange)="checkNameSpace()" [(ngModel)]="model.yourName"
                            placeholder="Enter first and  last name" #yourName="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && yourName.invalid }" required />
                        <div *ngIf="f.submitted && yourName.invalid" class="invalid-feedback">
                            <div *ngIf="yourName.errors.required">Name is required</div>
                        </div>
                        <div style="display: block;" class="invalid-feedback">
                            <div *ngIf="nameSpacing">Only spaces are not allowed</div>
                        </div>
                    </div>

                    <div class="col-12 mt-2 pd-0">
                        <div class="participants__group__header black">Email</div>
                        <input type="text" class="form-control" name="email" [(ngModel)]="model.email" #email="ngModel"
                            placeholder="Enter email address" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
                            required email />
                        <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                            <div *ngIf="email.errors.required">Email is required</div>
                            <div *ngIf="email.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>

                    <div class="col-12 mt-2 pd-0">
                        <div class="participants__group__header black">Room name</div>

                        <input type="text" class="form-control" name="roomName"  (ngModelChange)="checkRoomNameSpace()" [(ngModel)]="model.roomName"
                            placeholder="Enter room name" #roomName="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && roomName.invalid }" required />
                        <div *ngIf="f.submitted && roomName.invalid" class="invalid-feedback">
                            <div *ngIf="roomName.errors.required">Room name is required</div>
                        </div>

                        <div style="display: block;" class="invalid-feedback">
                            <div *ngIf="roomNameSpacing">Only spaces are not allowed</div>
                        </div>

                    </div>

                    <div class="col-12 mt-2 pd-0">
                        <div class="participants__group__header black">Room Url</div>

                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon3">illinois.oneclick.chat/</span>
                            </div>
                            <input (ngModelChange)="checkRoomURLSpace()" type="text" class="form-control" name="roomURL" [(ngModel)]="model.roomURL"
                                placeholder="Enter URL for your room" #roomURL="ngModel"
                                [ngClass]="{ 'is-invalid': f.submitted && roomURL.invalid }" required />
                        </div>
                        <div *ngIf="f.submitted && roomURL.invalid" class="invalid-feedback">
                            <div *ngIf="roomURL.errors.required">Room Url is required</div>
                        </div>

                        <div style="display: block;" class="invalid-feedback">
                            <div *ngIf="roomURLSpacing">Only spaces are not allowed</div>
                        </div>

                    </div>

                    <div class="col-12 mt-2 pd-0">
                        <button [disabled]="loading" type="submit" class="btn btn-primary btn-block tx-bold">
                            Save Room
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>