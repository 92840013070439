import { Component, OnInit } from '@angular/core';
import { CurrentService, ApiService } from '@services/index';
import { RoomService } from 'src/app/room/services/room.service';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {
  public companyName;
  public companyLongName;
  constructor(
    public api: ApiService,
    public room: RoomService,
    public dialog: DialogService,
    public current: CurrentService
  ) {}

  ngDestroy() {}
  ngOnInit() {
    this.companyName = environment.companyName;
    this.companyLongName = environment.companyLongName;
  }

  public createRoom() {
    const name = prompt('Type room name');
    if (!name) return;

    this.api.room.create(name).subscribe((response) => {
      console.log(response);
    });
  }

  public logout() {
    this.current.logout();
  }
}
