import { environment } from 'src/environments/environment';
import { Component, OnInit, Input } from '@angular/core';
import { MixpanelService } from '@services/index';

import LogRocket from 'logrocket';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'oneclick';


  constructor(private mixpanel: MixpanelService) { }

  ngOnInit(): void {
    if (environment.hasLogrocket) {
      LogRocket.init('oneclickchat/illinois');
    }
    this.mixpanel.initWithoutToken();
    this.mixpanel.trackWithoutUser('User visit', {});
  }
}
