import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { ApiService } from './api/api.service';
import { User, Room } from '@models/index';

@Injectable({ providedIn: 'root' })
export class CurrentService {
  public userCache$ = new BehaviorSubject<User>(null);
  public roomCache$ = new BehaviorSubject<Room>(null);

  constructor(
    private api: ApiService,
    private router: Router,
    private cookie: CookieService
  ) {}

  public redirectToRoomIfAuthenticated() {
    if (this.token) {
      this.fetchUser$.subscribe((user) => {
        this.redirectToOwnedRoom(user);
      },(err) => {
        console.log("User not authenticated")
      });
    }
  }

  public redirectToOwnedRoom(user: User) {
    if(window.location.href.indexOf('manage-rooms') > -1) {
      this.router.navigateByUrl('/admin/manage-rooms');

    } else{
      if (user && user.rooms && user.rooms.length) {
        this.router.navigate(['/waiting/' + user.rooms[0].name]);
      }
    }
  }


  public redirectToSignIn(email: string) {
    this.router.navigate(['/signin/' + email]);
  }

  public clear() {
    this.token = '';
    localStorage.removeItem('breakout');
    localStorage.removeItem('audioDevice');
    localStorage.removeItem('videoDevice');
    sessionStorage.clear();
    this.cookie.deleteAll();
  }

  public logout() {
    this.token = '';
    localStorage.removeItem('breakout');
    localStorage.removeItem('audioDevice');
    localStorage.removeItem('videoDevice');
    sessionStorage.clear();
    this.cookie.deleteAll();
    this.router.navigate(['/']);
  }

  public logoutWithRefresh() {
    this.token = '';
    localStorage.removeItem('breakout');
    localStorage.removeItem('audioDevice');
    localStorage.removeItem('videoDevice');
    sessionStorage.clear();
    this.cookie.deleteAll();
    window.location.reload();
  }

  public get fetchUser$(): Observable<User> {
    if (this.userCache$.value) {
      return this.userCache$;
    }

    return this.api.user.get().pipe(
      tap((user) => (this.user = user)),
      first()
    );
  }

  public refreshUser(): Observable<User> {
    return this.api.user.get().pipe(
      tap((user) => (this.user = user)),
      first()
    );
  }

  public set user(value: User) {
    this.userCache$.next(value);
  }
  public get user() {
    return this.userCache$.value;
  }

  public set room(value: Room) {
    this.roomCache$.next(value);
  }
  public get room() {
    return this.roomCache$.value;
  }

  public get token() {
    return this.cookie.get('token');
  }

  public set token(value: string) {
    this.cookie.set('token', value, 730, '/' );
  }

  private getExpireDate() {
    const today = new Date();
    const expireDate = today.setFullYear(today.getFullYear() + 1);
    return expireDate;
  }
}
