import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { Room, User } from '@models/index';

export interface TokenRequest {
  room: string;
  role: string;
}
export interface TokenResponse {
  apiKey: string;
  sessionId: string;
  token: string;
}

@Injectable({ providedIn: 'root' })
export class RoomApiService {

  public ipAddress: string;

  constructor(private http: HttpClient, private firestore: AngularFirestore) { }

  public token(payload: TokenRequest): Observable<TokenResponse> {
    const fromData = new FormData();
    fromData.append('room', payload.room);
    fromData.append('role', payload.role);

    return this.http
      .post(environment.apiUrl + 'room/token', fromData)
      .pipe(map((response) => response as TokenResponse));
  }

  public tokenRelayed(payload: TokenRequest): Observable<TokenResponse> {
    const fromData = new FormData();
    fromData.append('room', payload.room);
    fromData.append('role', payload.role);

    return this.http
      .post(environment.apiUrl + 'room/token-relayed', fromData)
      .pipe(map((response) => response as TokenResponse));
  }

  public tokenBreakout(sessionId: string): Observable<any> {
    const fromData = new FormData();
    fromData.append('sessionId', sessionId);
    return this.http
      .post(environment.apiUrl + 'room/breakout-session', fromData)
      .pipe(map((response) => response));
  }

  public tokenTemp(payload: TokenRequest): Observable<TokenResponse> {
    const fromData = new FormData();
    fromData.append('room', payload.room);
    fromData.append('role', payload.role);

    return this.http
      .post(environment.apiUrl + 'room/token-temp', fromData)
      .pipe(map((response) => response as TokenResponse));
  }

  public getParticipants(value: string): Observable<any> {
    const fromData = new FormData();
    fromData.append('sessionId', value);

    return this.http
      .post(environment.apiUrl + 'room/get-participants', fromData)
      .pipe(map((response) => response as any));
  }

  public roomsBreakout(value: string): Observable<TokenResponse> {
    const fromData = new FormData();
    fromData.append('value', value);

    return this.http
      .post(environment.apiUrl + 'room/breakout-rooms', fromData)
      .pipe(map((response) => response as TokenResponse));
  }

  public roomsBreakoutSession(sessionId: string, roomId: string, grouping: string, activeUsers, shuffle: string): Observable<any> {
    const fromData = new FormData();
    fromData.append('sessionId', sessionId);
    fromData.append('roomId', roomId);
    fromData.append('grouping', grouping);
    fromData.append('data', activeUsers);
    fromData.append('shuffle', shuffle);

    return this.http
      .post(environment.apiUrl + 'room/breakout-sessions', fromData)
      .pipe(map((response) => response as any));
  }

  public list(): Observable<Room[]> {
    const fromData = new FormData();

    return this.http
      .post(environment.apiUrl + 'room/list', fromData)
      .pipe(map((response) => response as Room[]));
  }

  public getInfo(sessionId: string): Observable<Room> {
    const fromData = new FormData();
    fromData.append('sessionId', sessionId);

    return this.http
      .post(environment.apiUrl + 'room/getinfo', fromData)
      .pipe(map((response) => response as Room));
  }

  public visit(roomId: string): Observable<boolean> {
    const fromData = new FormData();
    fromData.append('roomId', roomId);

    return this.http
      .post(environment.apiUrl + 'room/visit', fromData)
      .pipe(map((response) => response as boolean));
  }

  public exists(name: string): Observable<boolean> {
    const fromData = new FormData();
    fromData.append('name', name);

    return this.http
      .post(environment.apiUrl + 'room/exists', fromData)
      .pipe(map((response) => response as boolean));
  }

  public create(room: string): Observable<string> {
    const fromData = new FormData();
    fromData.append('room', room);

    return this.http
      .post(environment.apiUrl + 'room/create', fromData)
      .pipe(map((response) => response as string));
  }

  // tslint:disable-next-line: max-line-length
  public feedback(room: string, feedback: string, issueInformation: string, browser: string,
    browserVersion: string, os: string, osVersion: string, device: string, userAgent: string,
    networkSpeed: string): Observable<string> {
    const fromData = new FormData();
    fromData.append('room', room);
    fromData.append('feedback', feedback);
    fromData.append('issueInformation', issueInformation);
    fromData.append('browser', browser);
    fromData.append('browserVersion', browserVersion);
    fromData.append('os', os);
    fromData.append('osVersion', osVersion);
    fromData.append('device', device);
    fromData.append('userAgent', userAgent);
    fromData.append('networkSpeed', networkSpeed);

    return this.http
      .post(environment.apiUrl + 'room/send-feedback', fromData)
      .pipe(map((response) => response as string));
  }

  public favorite(roomId: string): Observable<boolean> {
    const fromData = new FormData();
    fromData.append('roomId', roomId);

    return this.http
      .post(environment.apiUrl + 'room/favorite', fromData)
      .pipe(map((response) => response as boolean));
  }

  public unfavorite(roomId: string): Observable<boolean> {
    const fromData = new FormData();
    fromData.append('roomId', roomId);

    return this.http
      .post(environment.apiUrl + 'room/unfavorite', fromData)
      .pipe(map((response) => response as boolean));
  }

  public switchMode(room: string, mode: string): Observable<string> {
    const fromData = new FormData();
    fromData.append('room', room);
    fromData.append('mode', mode);

    // Update room in firebase server
    const doc = this.firestore.collection('rooms').doc(room);
    const updateFirebase = doc.set({ mode });

    // Update room in server
    const updateServer = this.http
      .post(environment.apiUrl + 'room/switch-mode', fromData)
      .pipe(map((response) => response as string));

    return from(updateFirebase).pipe(switchMap(() => updateServer));
  }

  public roomChanges(room: string) {
    const doc = this.firestore.collection('rooms').doc<Room>(room);
    // return doc.valueChanges();

    return doc.snapshotChanges().pipe(
      map((snapshot) => ({
        id: snapshot.payload.id,
        ...snapshot.payload.data(),
      }))
    );
  }

  public banUser(room: string, user: string) {
    const fromData = new FormData();
    fromData.append('room', room);
    fromData.append('user', user);

    return this.http
      .post(environment.apiUrl + 'room/ban', fromData)
      .pipe(map((response) => response as boolean));
  }

  public banParticipant(ipAddress: string, roomName: string, username: string) {
    const fromData = new FormData();
    fromData.append('ipAddress', ipAddress);
    fromData.append('roomName', roomName);
    fromData.append('username', username);
    
    return this.http
      .post(environment.apiUrl + 'banning/ban', fromData)
      .pipe(map((response) => response as boolean));
  }

  public getIP(): Observable<any> {
    return this.http
    .get("https://api.ipify.org/?format=json")
    .pipe(map((response) => response as string));
  }

  public checkBanned(ipAddress: string, roomName: string) {
    const fromData = new FormData();
    fromData.append('ipAddress', ipAddress);
    fromData.append('roomName', roomName);

    return this.http
      .post(environment.apiUrl + 'banning/is-banned', fromData)
      .pipe(map((response) => response as boolean));
  }

  public update(roomId: string, data: Partial<Room>): Promise<void> {
    if (!roomId) return;
    // Update room in firebase server
    const doc = this.firestore.collection('rooms').doc(roomId);
    return doc.set(data, { merge: true });
  }

  public async accept(roomId: string, user: User): Promise<void> {
    this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('knocks')
      .doc(user.id)
      .delete();

    const doc = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('accepts')
      .doc(user.id);

    await doc.set({ ...user, time: Date.now() });
  }

  public accepts(roomId: string): Observable<User[]> {
    const collection = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('accepts');

    return collection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((snapchot) => snapchot.payload.doc.data() as User);
      })
    );
  }

  public async knock(roomId: string, user: User): Promise<void> {
    const doc = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('knocks')
      .doc(user.id);

    await doc.set({ ...user, time: Date.now() });
  }

  public handRaises(roomId: string): Observable<User[]> {
    const collection = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('handRaise');

    return collection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((snapchot) => snapchot.payload.doc.data() as User);
      })
    );
  }
  public async acceptHandRaise(roomId: string, user: User): Promise<void> {
    this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('handRaise')
      .doc(user.id)
      .delete();
  }

  public getCurrentRooms(): Observable<any[]> {
    const collection = this.firestore
      .collection('rooms');

    return collection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((snapchot) => snapchot.payload.doc.data() as any);
      })
    );
  }

  public knocks(roomId: string): Observable<User[]> {
    const collection = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('knocks');

    return collection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((snapchot) => snapchot.payload.doc.data() as User);
      })
    );
  }

  public events(roomId: string, eventType: string): Observable<User[]> {
    const collection = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection(eventType);

    return collection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((snapchot) => snapchot.payload.doc.data() as User);
      })
    );
  }

  public async addEvent(
    roomId: string,
    eventType: string,
    user: any
  ): Promise<void> {
    const doc = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection(eventType)
      .doc(user.id || user.userId);

    await doc.set({ ...user, time: Date.now() });
  }

  public async addRequest(roomId: string, user: User): Promise<void> {
    const doc = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('request')
      .doc(user.id);

    await doc.set({ ...user, time: Date.now() });
  }

  public requests(roomId: string): Observable<User[]> {
    const collection = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('request');

    return collection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((snapchot) => snapchot.payload.doc.data() as User);
      })
    );
  }
  public async acceptRequest(roomId: string, userId: any): Promise<void> {
    this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('request')
      .doc(userId)
      .delete();

  }

  public async removeAllRequest(roomId: string): Promise<void> {
    const collection = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('request');

    await collection
      .get()
      .toPromise()
      .then(
        (snapshot) => {
          let deletePromise = [];
          snapshot.forEach((item) => {
            deletePromise.push(item.ref.delete());
          });
          return Promise.all(deletePromise);
        }
      );
  }

  public async clearHandRaise(roomId: string, user: User): Promise<void> {
    const doc = this.firestore
      .collection('rooms')
      .doc(roomId);

    await doc.update(
      { users: [user] }
    );
  }

  public async bringToStage(roomId: string, user: User): Promise<void> {
    const doc = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('bringToStage')
      .doc(user.id);

    await doc.set({ ...user, time: Date.now() });
  }

  public async deleteBringToStage(roomId: string, user: User): Promise<void> {
    this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('bringToStage')
      .doc(user.id)
      .delete();
  }

  public async raiseHand(roomId: string, user: User): Promise<void> {
    const doc = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('handRaise')
      .doc(user.id);

    await doc.set({ ...user, time: Date.now() });
  }

  public async deleteActiveUser(roomId: string, user: User): Promise<void> {
    this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('activeUser')
      .doc(user.id)
      .delete();
  }

  public async addActiveUser(roomId: string, user: User): Promise<void> {
    const doc = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('activeUser')
      .doc(user.id);

    await doc.set({ ...user, time: Date.now() });
  }

  public async updateActiveUser(roomId: string, json: any, userId: string): Promise<void> {
    const doc = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('activeUser')
      .doc(userId);

    await doc.update(json);
  }

  public async updateVideoFirebase(roomId: string, json: any, user: any): Promise<void> {

    const doc = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('videoPlayer')
      .doc(user.id);

    await doc.set({ ...json, time: Date.now() });
  }

  public async deleteAllVideos(roomId: string): Promise<void> {
    const collection = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('videoPlayer');

    await collection
      .get()
      .toPromise()
      .then(
        (snapshot) => {
          let deletePromise = [];
          snapshot.forEach((item) => {
            deletePromise.push(item.ref.delete());
          });
          return Promise.all(deletePromise);
        }
      );
  }


  // public async removeUserByConnectionId(roomId: string, connectionId: string): Promise<void> {
  //   this.activeUsers(roomId).subscribe((users) => {
  //     users.forEach(u => {
  //       if (u.openTokConnectionId === connectionId) {
  //         this.deleteActiveUser(roomId, u);
  //       }
  //     });
  //   });
  // }

  public async removeAllActiveUsers(roomId: string): Promise<void> {
    const collection = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('activeUser');

    await collection
      .get()
      .toPromise()
      .then(
        (snapshot) => {
          let deletePromise = [];
          snapshot.forEach((item) => {
            deletePromise.push(item.ref.delete());
          });
          return Promise.all(deletePromise);
        }
      );
  }

  public async clearFirebase(roomId: string, entity: string): Promise<void> {
    const collection = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection(entity);

    await collection
      .get()
      .toPromise()
      .then(
        (snapshot) => {
          let deletePromise = [];
          snapshot.forEach((item) => {
            deletePromise.push(item.ref.delete());
          });
          return Promise.all(deletePromise);
        }
      );
  }

  public activeUsers(roomId: string): Observable<User[]> {
    const collection = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('activeUser');

    return collection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((snapchot) => snapchot.payload.doc.data() as User);
      })
    );
  }

  public async addSignal(roomId: string, type: string): Promise<void> {
    const doc = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('signal')
      .doc("signals");

    await doc.set({ type: type, time: Date.now() });

  }

  public getSignal(room: string): Observable<any> {

    return this.firestore
      .collection('rooms')
      .doc(room)
      .collection('signal')
      .doc("signals").valueChanges();
  }

  public async removeSignal(room: string): Promise<any> {

    const doc = this.firestore
      .collection('rooms')
      .doc(room)
      .collection('signal')
      .doc('signals');

    await doc.delete();
  }

  public async removeEvent(
    roomId: string,
    eventType: string,
    userId: string
  ): Promise<void> {
    const doc = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection(eventType)
      .doc(userId);

    await doc.delete();
  }

  public clearRoom(room: string): Promise<any> {
    // const fromData = new FormData();
    // fromData.append('room', room);

    // return this.http
    //   .post(environment.apiUrl + 'room/clear-session', fromData);

    let deletePromise = [];
    deletePromise.push(this.clearFirebase(room, 'activeUser'));
    deletePromise.push(this.clearFirebase(room, 'handRaise'));
    deletePromise.push(this.clearFirebase(room, 'bringToStage'));
    deletePromise.push(this.clearFirebase(room, 'accepts'));
    return Promise.all(deletePromise);
  }

  public getVideos(): Observable<any> {
    const fromData = new FormData();
    return this.http
      .post(environment.apiUrl + 'video/list', fromData)
      .pipe(map((response) => response as any));
  }

  public deleteVideos(videoId: string, user: any): Observable<any> {
    const fromData = new FormData();
    fromData.append('videoId', videoId);
    fromData.append('userId', user.id);
    return this.http
      .post(environment.apiUrl + 'video/delete', fromData)
      .pipe(map((response) => response as any));
  }

  public uploadVideos(file: any): Observable<any> {
    const fromData = new FormData();
    fromData.append('upload', file, file.name);
    fromData.append('name', file.name);
    return this.http
      .post(environment.apiUrl + 'video/set', fromData)
      .pipe(map((response) => response as any));
  }


  public updateStats(sessionId: string, roomId: string, activeUsers, mode: string): Observable<any> {
    const fromData = new FormData();
    fromData.append('sessionId', sessionId);
    fromData.append('roomId', roomId);
    fromData.append('userIds', activeUsers);
    fromData.append('mode', mode);

    return this.http
      .post(environment.apiUrl + 'room/update-stats', fromData)
      .pipe(map((response) => response as any));
  }

  public updatePresence(roomName: string, breakoutName: string, userId: string, username: string): Observable<any> {
    const fromData = new FormData();
    fromData.append('roomName', roomName);
    fromData.append('breakoutName', breakoutName);
    fromData.append('userId', userId);
    fromData.append('username', username);

    return this.http
      .post(environment.apiUrl + 'room/update-presence', fromData)
      .pipe(map((response) => response as any));
  }


}
