<app-header></app-header>
<div class="content">
  <div class="container text-center">
    <div class="image"><img src="../../../assets/images/icons8-spam.svg"></div>
    <div class="not-found">Sorry, we can't find this room. <br/> Please check if the spelling is correct.</div>
  </div>
</div>



