<div id="wrapper" *ngIf="frontendEnabled">
  <a class="accessibility" href="#main">Skip to Content</a>

  <app-header></app-header>

  <main id="main" role="main">
    <section class="section-create text-center bg-primary text-white py-5">
      <div class="container-xl">
        <div class="content-block px-sm-3 px-md-5 px-lg-6">
          <h1>Improving Health<br>Outcomes for Older Adults</h1>
          <p class="small-desc">
            NIH-Funded Telehealth and Telewellness Solutions for your Organization
          </p>
        </div>
        <div class="form-holder py-3 mb-5">
          <form action="#">
            <fieldset>
              <legend class="invisible">Create room form</legend>
              <div class="form-block">
                <div class="justify-content-center">
                  <div class="col-auto">
                    <button class="btn btn-secondary btn-create-account text-uppercase" type="submit">
                      LEARN MORE
                    </button>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
        <div class="illustration-block"><img src="assets/images/3.png" alt="image description" /></div>
      </div>
    </section>
    <section class="section-logos section-main text-center pt-5">
      <div class="container-xl">
        <h2>Trusted Around the World</h2>
        <div class="logos-container py-5">
          <ul class="logos-list list-unstyled d-flex flex-wrap justify-content-center align-items-center">
            <li class="logo-conainer p-3">
              <img src="assets/images/logo-nih.png" srcset="assets/images/logo-nih@2x.png 2x"
                alt="NIH National Institutes of Health" width="220" height="69" />
            </li>
            <li class="logo-conainer p-3">
              <img src="assets/images/logo-georgia-tech.png" srcset="assets/images/logo-georgia-tech@2x.png 2x"
                alt="Georgia Tech" width="208" height="89" />
            </li>
            <li class="logo-conainer p-3">
              <img src="assets/images/logo-alumni.png" srcset="assets/images/logo-alumni@2x.png 2x"
                alt="Syracuse University Alumni Association" width="135" height="76" />
            </li>
            <li class="logo-conainer p-3">
              <img src="assets/images/logo-hood.png" srcset="assets/images/logo-hood@2x.png 2x" alt="HOOD COLLEGE"
                width="208" height="63" />
            </li>
            <li class="logo-conainer p-3">
              <img src="assets/images/logo-posse.png" srcset="assets/images/logo-posse@2x.png 2x"
                alt="The POSSE Foundation, inc." width="280" height="47" />
            </li>
            <li class="logo-conainer p-3">
              <img src="assets/images/logo-illinois.png" srcset="assets/images/logo-illinois@2x.png 2x"
                alt="ILLINOIS University of Illinois at Urbana Champaign" width="213" height="58" />
            </li>
            <li class="logo-conainer p-3">
              <img src="assets/images/logo-univ-twente.png" srcset="assets/images/logo-univ-twente@2x.png 2x"
                alt="University of Twente The Netherlands" width="195" height="75" />
            </li>
            <li class="logo-conainer p-3">
              <img src="assets/images/logo-huaa.png" srcset="assets/images/logo-huaa@2x.png 2x"
                alt="HUAA Howard University Alumni Association" width="198" height="114" />
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="section-tabs-who">
      <div class="tabcontent-section  bg-primary text-white py-3 py-md-4 py-lg-5">
        <div class="container-xl">
          <div class="tab-content pb-2 mb-lg-1">
            <div class="container-xl  text-center">
              <h2>Built with Hassle-Free Video Chat</h2>
            </div>
            <div class="tab-pane fade show active" id="remote" role="tabpanel" aria-labelledby="remote-tab">
              <div class="row align-items-md-center">
                <div class="col-md-6">
                  <div class="tab-content-block">

                    <p class="small-desc-simple">
                      {{companyLongName}} is the easiest way to connect over video.
                    </p>
                    <p class="small-desc-simple">
                      It works on computers, tablets, and smartphones without a need to download any apps.
                    </p>
                    <p class="small-desc-simple">
                      Test it out in your own video chat room, and invite up to 20 people to join you by simply sharing a link.
                    </p>
                                      
                    <button class="btn btn-secondary btn-create-account text-uppercase" type="submit" (click)="continue()">
                      TRY FOR FREE
                    </button>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="img-holder">
                    <img src="assets/images/img-education.svg" alt="image description" width="508" height="420" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="content-section py-4 py-md-5 py-lg-6">
      <div class="container-xl">
        <div class="row align-items-md-center py-2 py-md-3 py-lg-4">
          <div class="col-md-6">
            <div class="img-holder py-3">
              <img src="assets/images/4.png" alt="image description" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="content-block">
              <h3>Private-Labeled for Your Organization</h3>
              <p class="small-desc-simple">
                Brand our video chat solutions as your own.
              </p>
              <p class="small-desc-simple">
                Or better yet, embed our video directly into your platform.
              </p>
              <p class="small-desc-simple">
                Our video solutions are HIPAA-compliant, easy-to-join, and customizable.
              </p>

              <button class="btn btn-secondary btn-create-account text-uppercase" type="submit" (click)="continue()">
                SCHEDULE A DEMO
              </button>
            </div>
          </div>
        </div>
        <div class="row align-items-md-center py-2 py-md-3 py-lg-4">
          <div class="col-md-6 order-md-2">
            <div class="img-holder py-3">
              <img src="assets/images/1.png" alt="image description" />
            </div>
          </div>
          <div class="col-md-6 order-md-1">
            <div class="content-block">
              <h3>Funded by the National Institutes on Health (NIH)</h3>
              <p class="small-desc-simple">
                $2 million in grant awards have helped us design the experience to maximize mental health benefits for older adults at risk for social isolation.
              </p>
              <p class="small-desc-simple">
                Stay updated on our research findings and results from our clinical trials here.
              </p>

            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-connected bg-primary text-white py-5 py-md-7 py-lg-8">
      <div class="container-xl">
        <div class="row align-items-md-center">
          <div class="col-md-5 order-md-2">
            <div class="img-holder pb-5 pb-md-0">
              <img src="assets/images/img-connected.svg" alt="image description" width="521" height="387" />
            </div>
          </div>
          <div class="col-md-7 order-md-1">
            <div class="form-holder text-center text-md-left pl-md-3 pl-lg-6 pl-xl-7">
              <form action="#">
                <fieldset>
                  <h3>Start connecting your community on the easiest-to-use video chat platform on the planet.</h3>
                  <p class="small-desc-simple">
                    Schedule a demo to learn more about our world-class video solutions.
                  </p>
                  <div class="form-block">
                    <div class="form-row justify-content-center justify-content-md-start">

                      <div class="col-auto">
                        <div class="form-group">
                          <button class="btn btn-secondary text-uppercase btn-create-account" type="submit" (click)="continue()">
                            SCHEDULE A DEMO
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>

  <app-footer></app-footer>

</div>