<app-header></app-header>
<div class="content">
  <div class="container">

    <div class="return">
      <a routerLink="/blog">Back to blog</a>
    </div>

    <div *ngIf="isLoading">
      <div class="tx-center tx-14 tx-semibold mg-t-0 tx-color-03 tx-uppercase mg-t-30">Loading about...</div>
        <div class="tx-center mg-t-10 mg-b-40">
          <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
      </div>    
    </div>

    <div *ngIf="content" class="post">
      <div class="title">{{content.title.iv}}</div>
      <div class="body" [innerHTML]="content.body.iv"></div>
    </div>

  </div>
</div>