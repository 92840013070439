import { Component, OnInit } from '@angular/core';
import { GlobalService } from '@services/index';
import { CMSApiService } from '@services/api/cms-api.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  content: string;
  isLoading = true;

  constructor(
    private globalService: GlobalService,
    private cms: CMSApiService
  ) { }

  ngOnInit() {
    this.load();
  }

  load() {
    this.cms.getToken().subscribe((retToken) => {
      this.globalService.cmsToken = retToken.access_token;
      this.cms.getAbout().subscribe((ret) => {
        this.content = ret.items[0].data.value.iv;
        console.log(this.content);
        this.isLoading = false;
      });
    });
  }
}
