import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';

// Interceptors
import { TokenInterceptor } from './interceptors/token.interceptor';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { DialogsModule } from './dialogs/dialogs.module';
import { ComponentsModule } from './components/components.module';
// Components
import { AppComponent } from './app.component';
import { FooterComponent } from './generic/footer/footer.component';
import { HeaderComponent } from './generic/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { HelpevComponent } from './room/dialogs/helpev/helpev.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { BillingComponent } from './pages/billing/billing.component';
import { CameraComponent } from './pages/camera/camera.component';
import { FaqComponent } from './pages/faq/faq.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { TeamsComponent } from './pages/teams/teams.component';
import { PressComponent } from './pages/press/press.component';
import { AboutComponent } from './pages/about/about.component';
import { PermissionComponent } from './pages/permission/permission.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { EjectedComponent } from './pages/ejected/ejected.component';
import { BannedComponent } from './pages/banned/banned.component';
import { LockedComponent } from './pages/locked/locked.component';
import { LeftComponent } from './pages/left/left.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { NotificationComponent } from './notification/notification.component';
import { NotificationService } from './services/notification.service';
import { RatingModule } from 'ng-starrating';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlogComponent } from './pages/blog/blog.component';
import { PostComponent } from './pages/post/post.component';
import { EventsComponent } from './pages/events/events.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { FoiComponent } from './foi/foi.component';
import { ManageRoomsComponent } from './admin/manage-rooms/manage-rooms.component';
import { ManageBannedComponent } from './admin/manage-banned/manage-banned.component';
import { ManageEventsComponent } from './admin/manage-events/manage-events.component';
import { ManageFeedbackComponent } from './admin/manage-feedback/manage-feedback.component';
import { AdminHeaderComponent } from './admin/admin-header/admin-header.component';
import { AddRoomComponent } from './admin/add-room/add-room.component';
import { ManageAccountComponent } from './admin/manage-account/manage-account.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SettingsComponent,
    BillingComponent,
    CameraComponent,
    FaqComponent,
    TermsComponent,
    PrivacyComponent,
    FooterComponent,
    HeaderComponent,
    TeamsComponent,
    PressComponent,
    AboutComponent,
    PermissionComponent,
    NotfoundComponent,
    LeftComponent,
    BannedComponent,
    EjectedComponent,
    LockedComponent,
    BlogComponent,
    EventsComponent,
    NotificationComponent,
    FeedbackComponent,
    HelpevComponent,
    PostComponent,
    FoiComponent,
    ManageRoomsComponent,
    ManageBannedComponent,
    ManageEventsComponent,
    ManageFeedbackComponent,
    AdminHeaderComponent,
    AddRoomComponent,
    ManageAccountComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    AppRoutingModule,
    AuthModule,
    DialogsModule,
    ComponentsModule,
    NgbModule,
    AccordionModule.forRoot(),
    BrowserAnimationsModule,
    RatingModule,
    AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [NotificationService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [NgbModule]
})
export class AppModule { }
