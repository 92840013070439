import { Component, OnInit } from '@angular/core';
import { PublisherService } from 'src/app/room/services/publisher.service';
import { ApiService, CurrentService, NotificationService } from '@services/index';

@Component({
  selector: 'app-ejected',
  templateUrl: './ejected.component.html',
  styleUrls: ['./ejected.component.css']
})
export class EjectedComponent implements OnInit {

  constructor(
    private current: CurrentService,
    public publisher: PublisherService,
  ) { }

  ngOnInit(): void {
    this.publisher.disconnectSession();
    this.current.clear();
  }

}
