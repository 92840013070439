<header class="header" id="header">
  <nav class="navbar navbar-expand-md navbar-dark" [ngStyle]="{'background-color': backgroundColor}">
    <div class="container-xl">
      <a class="navbar-brand text-white" [routerLink]="homepageUrl">
        <img *ngIf="logoDark !== ''" src="{{logoDark}}" class="img-logo">
        <span *ngIf="logoDark === ''">
          {{companyName}}
        </span>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">

        <ul class="navbar-nav navbar-right ml-auto" *ngIf="!isEvents && whiteLabel != 'lab'">
          <li class="nav-item"><a class="nav-link" href="/home">HOME</a></li>
          <li class="nav-item" *ngIf="whiteLabel != 'lab'"><a class="nav-link" href="/events">PUBLIC EVENTS</a></li>
          <li class="nav-item" *ngIf="whiteLabel == 'lab'"><a class="nav-link" href="/rsvp">RSVP</a></li>
          <li class="nav-item"><a class="nav-link" href="https://get.oneclick.chat/organizations" target="_new">ENTERPRISES</a></li>
          <li class="nav-item"><a class="nav-link" href="https://calendly.com/dillonmyers/15-minute-meeting" target="_new">SCHEDULE A DEMO</a></li>
          <li class="nav-item login" *ngIf="!isAuthenticated"><a class="nav-link" routerLink="/signin">SIGN IN</a></li>
          <li class="nav-item login" *ngIf="isAuthenticated"><a class="nav-link" routerLink="/">MY ROOM</a></li>
        </ul>

        <ul class="navbar-nav navbar-right ml-auto" *ngIf="!isEvents && whiteLabel == 'lab'">
          <li class="nav-item"><a class="nav-link" href="tel:2173335498">Support: 217.333.5498</a></li>
        </ul>

        <ul class="navbar-nav navbar-right ml-auto" *ngIf="isEvents">
          <li (click)="dialog.toggle('helpev')" class="nav-item login"><a class="nav-link link">SUPPORT</a></li>
        </ul>
      </div>
    </div>
  </nav>


</header>


<app-dialog name="helpev" header="help">
  <app-helpev></app-helpev>
</app-dialog>