import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  version;
  companyName;

  constructor() { }

  ngOnInit() {
    this.version = environment.version + '.' + environment.build;
    this.companyName = environment.companyName;
  }

}
