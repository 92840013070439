<div class="pt-5 pt-lg-6 pt-xl-7 pb-3 pb-lg-5" id="footer">
  <a class="accessibility" href="#wrapper">Back to top</a>
  <div class="container-xl">
    <aside class="footer-top pb-lg-3">
      <div class="row align-items-xl-center justify-content-xl-between">
        <div class="col-auto">
          <a class="logo-footer navbar-brand mb-3 mb-xl-5" [routerLink]="['/']"> <mark
              class="text-primary p-0">One</mark>Click</a>
        </div>
        <div class="footer-nav-block1 col-auto col-md-12 col-xl-9">
          <div class="row">
            <div class="footer-column col">
              <strong class="h5 text-uppercase tx-bold">About us</strong>
              <ul class="footer-nav list-unstyled">
                <li><a [routerLink]="['/about']">About us</a></li>
                <li><a [routerLink]="['/blog']">Blog</a></li>
                <li><a [routerLink]="['/press']">Press</a></li>
                <li><a [routerLink]="['/faq']">FAQ</a></li>
                <li><a [routerLink]="['/foi']">FCOI Policy</a></li>
              </ul>
            </div>
            <div class="footer-column col">
              <strong class="h5 text-uppercase tx-bold">Support</strong>
              <ul class="footer-nav list-unstyled">
                <li>Email: <a href="mailto:contact@oneclick.chat">contact@oneclick.chat</a></li>
                <li>Tel: +1-267-493-4371</li>
              </ul>
            </div>
            <div class="col-lg-auto">
              <ul class="social-networks list-inline text-center text-md-left py-3 py-md-0">
                <li class="list-inline-item">
                  <a href="https://twitter.com/oneclickdotchat"><span class="invisible">Twitter</span><i
                      class="icon-twitter"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="https://www.facebook.com/oneclick.chat"><span class="invisible">Facebook</span><i
                      class="icon-facebook"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="https://www.instagram.com/oneclick.chat/"><span class="invisible">Instagram</span><i
                      class="icon-instagram"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="https://www.linkedin.com/company/potluck-llc."><span class="invisible">Linkedin</span><i
                      class="icon-linkedin"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </aside>
    <hr class="divider mt-xl-4" />
    <footer class="footer-bottom py-2">
      <div class="row align-items-sm-center">
        <div class="col-sm-6 text-center text-sm-left">
          <p class="mb-sm-0">© Copyright {{companyName}}. All Rights Reserved - v{{version}}</p>
        </div>
        <div class="col-sm-6">
          <ul class="nav justify-content-center justify-content-sm-end">
            <li class="nav-item"><a class="nav-link active" [routerLink]="['/privacy']">Privacy Policy</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="['/terms']">Terms Of Use</a></li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</div>