import { Component, OnInit } from '@angular/core';
import { CMSApiService } from '@services/api/cms-api.service';
import { GlobalService } from '@services/global.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  contentItems;
  isLoading = true;

  constructor(
    private globalService: GlobalService,
    private cms: CMSApiService
  ) { }

  ngOnInit() {
    this.load();
  }

  load() {
    this.cms.getToken().subscribe((retToken) => {
      this.globalService.cmsToken = retToken.access_token;
      this.cms.getBlog().subscribe((ret) => {
        console.log(ret);
        this.contentItems = ret.items;
        this.isLoading = false;
      });
    });
  }

}
