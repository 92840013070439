import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Contact } from '@models/index';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ContactApiService {
  constructor(private http: HttpClient) {}

  public list(): Observable<Contact[]> {
    const fromData = new FormData();

    return this.http
      .post(environment.apiUrl + 'contact/list', fromData)
      .pipe(map((response) => response as Contact[]));
  }

  public invite(payload: any): Observable<boolean> {
    const fromData = new FormData();
    fromData.append('roomName', payload.roomName);
    fromData.append('email', payload.email);
    fromData.append('name', payload.name);
    fromData.append('subject', payload.subject);
    fromData.append('date', payload.date);
    fromData.append('time', payload.time);
    fromData.append('length', payload.duration);
    fromData.append('isScheduled', payload.isScheduled);
    fromData.append('timeZoneOffset', payload.timeZoneOffset);

    return this.http
      .post(environment.apiUrl + 'contact/invite', fromData)
      .pipe(map((response) => response as boolean));
  }

  public delete(email: string): Observable<boolean> {
    const fromData = new FormData();
    fromData.append('email', email);

    return this.http
      .post(environment.apiUrl + 'contact/delete', fromData)
      .pipe(map((response) => response as boolean));
  }
}
