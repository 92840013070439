import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ApiService, CurrentService } from '@services/index';
import { Room } from '@models/index';

@Component({
  selector: 'app-locked',
  templateUrl: './locked.component.html',
  styleUrls: ['./locked.component.css'],
})
export class LockedComponent implements OnInit, OnDestroy {
  private room: Room;

  constructor(
    private api: ApiService,
    private current: CurrentService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnDestroy() {}

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        filter((params) => !!params.name),
        untilDestroyed(this)
      )
      .subscribe((params) => {
        combineLatest(
          this.current.fetchUser$,
          this.api.room.roomChanges(params.name),
          this.api.room.accepts(params.name)
        ).subscribe(([user, room, accepts]) => {
          this.room = room;

          const unlocked = !room.isLockedFB;
          const isAccepted = !!accepts.find((u) => u.id === user.id);

          if (unlocked || isAccepted) {
            this.router.navigate(['/waiting/' + room.name]);
          }
        });
      });
  }

  public knock() {
    this.current.fetchUser$.subscribe((user) => {
      this.api.room.knock(this.room.name, user);
    });
  }
}
