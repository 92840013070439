<div id="wrapper">
    <app-admin-header></app-admin-header>

    <div *ngIf="isAdmin === 0" class="admin-mode-container">
        <div class="row mg-b-10 mg-t-10">
            <div class="col-9">
                Restricted access
            </div>
        </div>
    </div>

    <div *ngIf="isAdmin === 1" class="admin-mode-container">
        <div class="row mg-b-10">
            <div class="col-9">
                <h4 id="section1" *ngIf="!loading" class="float-left">{{organization?.name}}</h4>
                <button *ngIf="!loading" (click)="dialog.toggle('addRoom');"
                    class="btn btn-sm btn-primary float-left ml-4"> <i class="fa fa-plus"></i> Add </button>

                <!-- <button *ngIf="allOrganization && allOrganization.length < 1" routerLink="/admin/manage-account"
                    class="btn btn-sm btn-primary float-left ml-4"> <i class="fa fa-plus"></i> Add organization</button> -->
            </div>
            <div class="col-3" *ngIf="allRooms && allRooms.length > 0">
                <!-- <input type="search" class="form-control" placeholder="Search..." /> -->
            </div>
        </div>

        <div class="table-responsive" *ngIf="allRooms && allRooms.length > 0">
            <table class="table table-striped mg-b-0">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Date Last Used</th>
                        <th scope="col">Date Created</th>
                        <th scope="col">Room Link</th>
                        <th scope="col">Room Owner Email</th>
                        <th scope="col" class="text-center">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let r of allRooms">
                        <th scope="row">{{r.name}}</th>
                        <td>
                            <span *ngIf=" r.lastAccessed === '0001-01-01T00:00:00.0000000Z' "
                                class="badge badge-primary"> New</span>
                            <ng-conatiner *ngIf=" r.lastAccessed !== '0001-01-01T00:00:00.0000000Z' ">
                                {{r.lastAccessed | date}}
                            </ng-conatiner>
                        </td>
                        <td>{{r.createdOn | date}}</td>
                        <td><a href="https://illinois.oneclick.chat/{{r.name}}"
                                target="_blank">https://illinois.oneclick.chat/{{r.name}}</a></td>
                        <td> {{r.ownerUserEmail}} </td>
                        <td class="text-center">
                            <img style="width: 20px;cursor: pointer;" class="favorite-icon right-nav-icon-top"
                                (click)="deleteRoom(r.id);" src="../../../assets/icons/icons8-trash.svg" />
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <div class="table-responsive mt-5" *ngIf="allRooms && allRooms.length < 1">
            <p>No rooms found.</p>
        </div>

        <div class="table-responsive mt-5" *ngIf="loading">
            <p>Loading...</p>
        </div>
    </div>

    <app-dialog name="addRoom" header="Add a room">
        <app-add-room (roomCreated)="roomSuccess($event)" [organizationId]="organization?.id"></app-add-room>
    </app-dialog>

</div>