import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ApiService } from '@services/index';
import { RoomMode, Room } from '@models/index';

const FavoriteEmptyMessage = `You haven't favorited any rooms yet.`;

interface RoomGroup {
  header: string;
  items: Room[];
  emptyMessage?: string;
}

@Component({
  selector: 'app-room-list',
  templateUrl: './room-list.component.html',
  styleUrls: ['./room-list.component.css'],
})
export class RoomListComponent implements OnInit {
  public list: RoomGroup[] = [];
  public rooms = [];
  public listLoading = false;
  public loading = false;
  public exists = false;
  public search = new FormControl();
  public form = new FormGroup({ search: this.search });

  constructor(private api: ApiService, private router: Router) { }

  public get showError() {
    return this.search.value && !this.loading && !this.exists;
  }

  ngOnDestroy() { }
  
  ngOnInit(): void {
    this.fetch();

    this.search.valueChanges
      .pipe(
        tap(() => {
          this.loading = true;
          this.exists = false;
        }),
        debounceTime(1000),
        untilDestroyed(this),
        switchMap((search) => this.api.room.exists(search))
      )
      .subscribe((exists) => {
        this.exists = !exists;
        this.loading = false;
      });
  }

  public fetch() {
    this.listLoading = true;
    this.api.room
      .list()
      .pipe(untilDestroyed(this))
      .subscribe((rooms) => {
        this.rooms = rooms;
        this.list = this.groupList(rooms);
        this.listLoading = false;
      });
  }

  public connect() {
    if (this.exists) {
      const roomName = this.search.value;
      const path = '/waiting/' + roomName;

      // this.router.navigate([path]);
      window.location.href = path;

      this.exists = false;
      this.search.setValue('', { emitEvent: false });
    }
  }

  public updateFavorite(room: Room, isFavorited: boolean) {
    const update = isFavorited
      ? this.api.room.favorite(room.id)
      : this.api.room.unfavorite(room.id);

    update.subscribe((success) => {
      room.isFavorited = isFavorited;
      this.list = this.groupList(this.rooms);
    });
  }

  private groupList(rooms: Room[]) {
    const list: RoomGroup[] = [];

    const owned = rooms.filter((room) => room.mode === RoomMode.private);
    let notOwned = rooms.filter((room) => room.mode !== RoomMode.private);

    notOwned = notOwned.sort((a: Room, b: Room) => (a.mode < b.mode ? 1 : -1));

    const favourites = notOwned.filter((room) => room.isFavorited);
    const other = notOwned.filter((room) => !room.isFavorited);

    list.push({ header: 'Your room', items: owned });

    if (favourites.length > 0) {
      list.push({
        header: 'Favourites',
        items: favourites,
        emptyMessage: FavoriteEmptyMessage,
      });
    }

    if (other.length > 0) {
      list.push({ header: 'Previously visited rooms', items: other });
    }

    return list;
  }
}
