import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Room } from '@models/index';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.css'],
})
export class PermissionComponent implements OnInit, OnDestroy {
  private room: Room;

  constructor(
    private router: Router,
  ) { }

  ngOnDestroy() { }

  ngOnInit(): void {
  }

  public backToRoom() {
    this.router.navigate(['/signin/']);

  }
}
