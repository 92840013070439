import {
  Component, OnInit, OnDestroy, ViewChild,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ApiService, CurrentService, NotificationService, MixpanelService } from '@services/index';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { User, Room } from '@models/index';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-bounce',
  templateUrl: './bounce.component.html',
  styleUrls: ['./bounce.component.css'],
})
export class BounceComponent implements OnInit, OnDestroy {

  @ViewChild('publisherDiv', { static: true })
  public publisherDiv: ElementRef<HTMLVideoElement>;

  isAnonymous = false;

  loading = false;
  redirecting = false;
  showName = false;
  error = '';
  room = '';
  isCameraInitalized = false;
  adBlockEnabled = false;
  public loadingVideo;
  public videoSrc;

  form = new FormGroup({
    name: new FormControl('', [
      Validators.required,
    ]),
    email: new FormControl('', [
      Validators.email,
    ]),
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private mixpanel: MixpanelService,
    private api: ApiService,
    private current: CurrentService
  ) { }

  ngOnDestroy() { }

  ngOnInit(): void {

    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((queryParams) => {

        const email: string = queryParams.e;
        const code: string = queryParams.c;
        let room: string = queryParams.r;

        room = room.replace('@', '').replace('/', '');

        this.room = room;
        this.loading = true;

        if (!email && !code) {

          this.isAnonymous = true;
          this.loading = false;

        } else {
          this.isAnonymous = false;
          this.api.user.autosign(email, code).subscribe(
            (token) => {

              this.loading = false;

              if (token) {
                this.current.token = token;
                this.current.fetchUser$.subscribe((user) => {
                  if (!user.name) {
                    this.showName = true;
                  } else {
                    this.redirecting = true;
                    this.router.navigate(['/waiting/' + room]);
                  }
                });
              }

            }, (error) => {
              this.error = error;
              this.loading = false;
            }
          );
        }

        console.log(this.isAnonymous);

      });
    this.detectAdBlock()

    this.loadingVideo = true;
    this.getMediaStream()
      .then((stream) => {
        this.loadingVideo = false;
        this.isCameraInitalized = true;
      })
      .catch((err) => {
        console.log(err);
      });

  }

  async updateName() {
    this.loading = true;
    this.api.user.updateName(this.form.value.name).subscribe(
      () => {
        this.current.fetchUser$.subscribe(() => {
          this.redirecting = true;
          this.router.navigate(['/waiting/' + this.room]);
        });
      }, (error) => {
        this.error = error;
        this.loading = false;
      }
    );
  }

  async anonymousSignUp() {
    if (!this.form.value.name) {
      this.error = 'Insert your name to continue';
      return;
    }

    if (this.form.controls.email.errors !== null && this.form.controls.email.errors.email) {
      this.error = 'Invalid email address';
      return;
    }


    const name = this.form.value.name;
    const email = this.form.value.email;

    this.loading = true;
    this.cookieService.set('anonymous', '1');
    this.cookieService.set('show_welcome', 'no');
    this.api.user.anonymousSignUp(name, email).subscribe((token) => {
      this.loading = false;
      if (token) {
        this.current.token = token;
        this.showName = true;
        this.redirecting = true;
        this.current.user = new User();
        this.current.user.username = name;
        this.current.user.name = name;
        this.current.user.email = email;
        this.router.navigate(['/waiting/' + this.room]);
        this.mixpanel.init("0", this.current.user);
        this.mixpanel.track('Anonymous SignUp', {
          user: this.current.user,
        });
      }
    }, (error) => {
      this.loading = false;
    }
    );
  }


  private getMediaStream(): Promise<MediaStream> {

    const video_constraints = { video: true };
    const _video = this.publisherDiv.nativeElement;
    return new Promise<MediaStream>((resolve, reject) => {
      // (get the stream)
      return navigator.mediaDevices.
        getUserMedia(video_constraints)
        .then(stream => {
          (<any>window).stream = stream; // make variable available to browser console
          _video.srcObject = stream;
          // _video.src = window.URL.createObjectURL(stream);
          _video.onloadedmetadata = function (e: any) { };
          _video.play();
          return resolve(stream);
        })
        .catch(err => reject(err));
    });
  }



  async detectAdBlock() {
    const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
    try {
      await fetch(new Request(googleAdUrl)).catch(_ => this.adBlockEnabled = true)
    } catch (e) {
      this.adBlockEnabled = true
    } finally {
      console.log(`AdBlock Enabled: ${this.adBlockEnabled}`)
    }
  }

}
