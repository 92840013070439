<div class="row justify-content-center align-items-center" [hidden]="loadingVideo">
  <div class="col-12">
    <!-- <h4 class="text-left">
      Check your camera settings
    </h4> -->

    <div class="stream-element pos-relative">
      <div *ngIf="publishing" class="stream-controls pos-absolute t-0 pd-10">
        <div class="row">
          <div class="col">
            <div *ngIf="current.user" class="stream-name text-capitalize">{{ current.user.name }}
              <span *ngIf="!publisherMuted"><small> (Muted) </small> </span>
            </div>
          </div>
        </div>
      </div>
      <div class="stream-element" [class.publishing]="publishing" #publisherDiv></div>
    </div>

  </div>
</div>

<div class="row" *ngIf="loadingVideo">
  <div class="col-12 ">
    <div class="stream-element loader-screen justify-content-center align-items-center d-flex">
      Checking Audio Video...
    </div>
  </div>
</div>