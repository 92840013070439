import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { CurrentService } from '@services/current.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class IllinoisGuard implements CanActivate {
  constructor(private router: Router, private current: CurrentService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.current.token && environment.whiteLabel === 'lab') {
      return true;
    }
    let _url = state.url.replace('/', '');
    if (_url === 'admin/manage-rooms') {
      this.router.navigateByUrl('/signin?manage-rooms=true');
    } else {
      this.router.navigate(['/bounce'], {
        queryParams: { r: _url },
      });
    }

    return false;
  }
}
