<div class="room-list">
  <form [formGroup]="form" class="room-list__form col-sm-12 align-self-end">
    <div class="row">
      <div class="col-sm-7 pd-r-0">
        <input #input matInput name="search" class="form-control" placeholder="Type room name" autocomplete="off" formControlName="search" />
        <div *ngIf="showError" class="invalid-feedback">
          Room does not exist
        </div>
      </div>
      <div class="col-sm-5 mg-mobile">
        <button type="submit" class="btn btn-brand-02 tx-13 tx-bold wd-100p" [disabled]="!exists" (click)="connect()">
          <span *ngIf="loading && search.value" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          CONNECT
        </button>
      </div>
    </div>
  </form>

  <div *ngIf="listLoading" class="text-center text-secondary">
    <div class="spinner-border" role="status"></div>
    <div>Loading...</div>
  </div>
  <div class="scrollable-container">
    <ng-container *ngIf="!listLoading && list">
      <ng-container *ngFor="let group of list">
        <div class="room-list__group">
          <div class="room-list__group__header">{{ group.header }}</div>

          <ng-container *ngIf="!group.items.length">
            <div class="mg-t-10 tx-black">{{ group.emptyMessage }}</div>
          </ng-container>

          <ng-container *ngFor="let item of group.items">
            <div class="room-list__group__item">
              <div class="d-flex align-items-end">
                <app-star *ngIf="item.mode !== 'private'" [isFavorited]="item.isFavorited" (changed)="updateFavorite(item, $event)"></app-star>
                <strong>{{ item.name }}</strong> <span *ngIf="item.count">({{ item.count }})</span>
              </div>
              <div>
                <a href="/waiting/{{ item.name }}" class="btn btn-xs btn-danger tx-bold">CONNECT</a>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>