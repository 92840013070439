import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CMSApiService } from '@services/api/cms-api.service';
import { GlobalService } from '@services/global.service';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class PostComponent implements OnInit {

  constructor(
    private cms: CMSApiService,
    private route: ActivatedRoute,
    private globalService: GlobalService) { }

  content;
  id;
  isLoading = true;

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.isLoading = false;
      this.id = params['id'];
      if (this.id) {
        this.cms.getToken().subscribe((retToken) => {
          this.globalService.cmsToken = retToken.access_token;
          this.cms.getBlogItem(this.id).subscribe((ret) => {
            this.content = ret.data;
            console.log(this.content);
          });
        });
      }
    });
  }
  
}
