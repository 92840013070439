import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ApiService, CurrentService, NotificationService } from '@services/index';
import { FormGroup, FormControl } from '@angular/forms';
import { User, Room } from '@models/index';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-as',
  templateUrl: './as.component.html',
  styleUrls: ['./as.component.css']
})
export class AsComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private api: ApiService,
    private current: CurrentService
  ) { }

  ngOnInit() {
    this.load();
  }

  load() {
    this.activatedRoute.queryParams
      .subscribe((queryParams) => {

        const email: string = queryParams.email;
        const name: string = queryParams.name;
        const room: string = queryParams.room;
        const tag: string = queryParams.tag;

        console.log('requesting as');

        this.api.user.autoSignup(email, name, room, tag).subscribe((token) => {
          console.log('requested as');
          console.log(token);
          if (token) {
            this.current.token = token;
            this.current.fetchUser$.subscribe((user) => {
              this.router.navigate(['/waiting/' + room]);
            });
          }
        });

        console.log('email: ' + email + ' - name:' + name + ' - room: ' + room + ' - tag: ' + tag);
      });
  }

}
