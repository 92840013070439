import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentChangeAction,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Message } from '@models/message';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({ providedIn: 'root' })
export class MessagesApiService {
  public unseenMessageCount = 0;

  constructor(private firestore: AngularFirestore, private http: HttpClient) { }

  public roomMessages(roomId: string): Observable<Message[]> {
    const collection = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection<Message>('messages');

    return collection.snapshotChanges().pipe(this.map<Message>());
  }

  public async create(roomId: string, data: Message): Promise<string> {
    const collection = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection<Message>('messages');

    const payload = { created: Date.now(), ...data };
    const created = await collection.add(payload);
    return created.id;
  }

  public update(data: Message): Promise<void> {
    return this.firestore.doc(data.id).update({ ...data });
  }

  public async removeAll(roomId: string): Promise<void> {
    const collection = this.firestore
      .collection('rooms')
      .doc(roomId)
      .collection('messages');

    await collection
      .get()
      .toPromise()
      .then(
        (snapshot) => {
          let deletePromise = [];
          snapshot.forEach((item) => {
            deletePromise.push(item.ref.delete());
          });
          return Promise.all(deletePromise);
        }
      );
  }

  public remove(id: string): Promise<void> {
    return this.firestore.doc(id).delete();
  }

  private map<T>() {
    return (source: Observable<DocumentChangeAction<T>[]>) =>
      source.pipe(
        map((changes: DocumentChangeAction<T>[]) =>
          changes.map((change: DocumentChangeAction<T>) => ({
            // id: change.payload.doc.id,
            ...(change.payload.doc.data() as T),
          }))
        )
      );
  }


  public getPinnedMessage(roomId: string): Observable<any[]> {
    const fromData = new FormData();
    fromData.append('roomId', roomId);

    return this.http
      .post(environment.apiUrl + 'room/get-pinned-message', fromData)
      .pipe(map((response) => response as []));
  }

  public unPinMessage(roomId: string): Observable<any[]> {
    const fromData = new FormData();
    fromData.append('roomId', roomId);

    return this.http
      .post(environment.apiUrl + 'room/unpin-message', fromData)
      .pipe(map((response) => response as []));
  }


  public addPinnedMessage(roomId: string, value: string): Observable<boolean> {
    const fromData = new FormData();
    fromData.append('roomId', roomId);
    fromData.append('message', value);


    return this.http
      .post(environment.apiUrl + 'room/pin-message', fromData)
      .pipe(map((response) => response as boolean));
  }


}
