import { Component, OnInit, Input } from '@angular/core';
import { DialogService } from '../dialog.service';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
})
export class DialogComponent implements OnInit {
  @Input() name: string;
  @Input() header: string;
  @Input() visible: boolean = false;

  constructor(public dialog: DialogService) {}

  ngOnDestroy() {}

  ngOnInit(): void {
    this.dialog.changes$
      .pipe(untilDestroyed(this))
      .subscribe((dialogs: Record<string, boolean>) => {
        this.visible = dialogs[this.name];
      });
  }

  public close() {
    this.dialog.closeAll();
  }
}
