import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter } from 'rxjs/operators';
import { AuthService } from '@services/auth.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CurrentService } from '@services/current.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  providers: [AuthService],
})
export class SignupComponent implements OnInit, OnDestroy {
  roomNameUpdate = new Subject<string>();
  signinform: FormGroup;
  submitted = false;

  constructor(
    public auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,
    private current: CurrentService,
    private formBuilder: FormBuilder
  ) { }

  ngOnDestroy() { }

  ngOnInit() {

    if (this.current.token) {
      this.current.logoutWithRefresh();
    }

    this.loadMetaData();

    this.activatedRoute.params
      .pipe(filter((params) => !!params.room))
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        this.auth.form.patchValue({ room: params.room });
      });

    this.roomNameUpdate.pipe(
      debounceTime(700),
      distinctUntilChanged())
      .subscribe(value => {
        let payload = {
          room: this.signinform.controls.room.value
        }
        this.auth.checkRoom(payload);
      });

    this.signinform = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      room: ['', [Validators.required, Validators.maxLength(20)]],
      code: ['', [Validators.required, Validators.minLength(6)]],
    });
  }


  onSubmit() {
    this.submitted = true;
    debugger;
    if (this.auth.codeSent) {
      if (this.signinform.controls.code.errors === null) {
        let payload = {
          name: this.signinform.controls.name.value,
          email: this.signinform.controls.email.value,
          room: this.signinform.controls.room.value,
          code: this.signinform.controls.code.value,
        }
        this.auth.confirm(payload);
      } else {
        return;
      }
    } else {
      if (
        this.signinform.controls.name.errors === null &&
        this.signinform.controls.email.errors === null &&
        this.signinform.controls.room.errors === null
      ) {
        let payload = {
          name: this.signinform.controls.name.value,
          email: this.signinform.controls.email.value,
          room: this.signinform.controls.room.value
        }
        this.auth.signup(payload);
      } else {
        return;
      }
    }
  }

  get f() { return this.signinform.controls; }

  loadMetaData() {
    const title = 'Free Sign-Up for Easy to Use Video Chat | No Download Needed';
    const description = 'Join for free today to video chat with friends and family. No download required. Get your own custom link.';

    this.titleService.setTitle(title);
    this.metaTagService.updateTag(
      { name: 'description', content: description }
    );
  }

}
