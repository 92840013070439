import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from '@services/index';
import { NotificationService } from '@services/index';

@Component({
  selector: 'app-add-room',
  templateUrl: './add-room.component.html',
  styleUrls: ['./add-room.component.css']
})
export class AddRoomComponent implements OnInit {

  @Input() organizationId: any;
  @Output() roomCreated: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public notificationService: NotificationService,
    public api: ApiService,
  ) { }

  model: any = {};
  loading = false;
  nameSpacing = false;
  roomNameSpacing = false;
  roomURLSpacing = false;
  ngOnInit(): void {
  }

  onSubmit(f: any) {
    if (this.checkNameSpace() || this.checkRoomNameSpace() || this.checkRoomURLSpace()) {
      return;
    }
    this.loading = true;
    this.api.management.addRoom(1, this.model.yourName, this.model.email, this.model.roomName, this.model.roomURL).subscribe((r: any) => {
      this.loading = false;
      f.reset();
      if (r) {
        this.notificationService.success("Room created");
        this.roomCreated.emit(true);
      }
    }, (error) => {
      this.notificationService.error("Error creating room");
    });
  }

  checkNameSpace() {
    this.nameSpacing = this.model.yourName.replace(/ /g, '') === '';
    return (this.nameSpacing);
  }

  checkRoomNameSpace() {
    this.roomNameSpacing = this.model.roomName.replace(/ /g, '') === '';
    return (this.roomNameSpacing);
  }

  checkRoomURLSpace() {
    this.roomURLSpacing = this.model.roomURL.replace(/ /g, '') === '';
    return (this.roomURLSpacing);
  }
}
