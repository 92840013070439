import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-five-star',
  templateUrl: './five-star.component.html',
  styleUrls: ['./five-star.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiveStarComponent implements OnInit {
  @Input() value: number = 0;
  @Output() rated = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  rate() {
    this.rated.emit(this.value);
  }
}
