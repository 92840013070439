import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';
import { CookieService } from 'ngx-cookie-service';
import NetworkTest, { ErrorNames } from 'opentok-network-test-js';
import * as OT from '@opentok/client';
import { GlobalService } from '@services/index';


@Injectable({ providedIn: 'root' })
export class OpentokService {
  session: OT.Session;
  token: string;
  constructor(
    public globalService: GlobalService,
    private api: ApiService, private cookieService: CookieService
  ) { }

  public getOT() {
    return OT;
  }

  public async initSession(room: string, role: string): Promise<OT.Session> {
    const opentok = this.getOT();
    // const isEventMode = sessionStorage.getItem("current_room_mode") == 'event';
    // let response;
    // if (isEventMode) {
    //   response = await this.api.room.tokenRelayed({ room, role }).toPromise();
    //   console.log("Created relayed token");
    // } else {
    //   response = await this.api.room.token({ room, role }).toPromise();
    // }
    const response = await this.api.room.token({ room, role }).toPromise();
    this.session = opentok.initSession(response.apiKey, response.sessionId);
    this.token = response.token;
    this.cookieService.set("apiKey", response.apiKey);
    return this.session;
  }

  public async initSessionById(sessionId: string): Promise<OT.Session> {
    const opentok = this.getOT();
    const response = await this.api.room.tokenBreakout(sessionId).toPromise();
    this.session = opentok.initSession("46892524", sessionId);
    this.token = response;

    return this.session;
  }

  public async testSession(): Promise<any> {
    const response = await this.api.room.tokenBreakout('1_MX40Njg5MjUyNH5-MTYxODgxNDQyNzk5Nn5MekYrME90MllnRWwrK3J2OUMyaVhXeHV-QX4').toPromise();

    const otNetworkTest = new NetworkTest(<any>OT, {
      apiKey: '46892524',
      sessionId: '1_MX40Njg5MjUyNH5-MTYxODgxNDQyNzk5Nn5MekYrME90MllnRWwrK3J2OUMyaVhXeHV-QX4',
      token: response
    });

    return otNetworkTest.testConnectivity();
  }

  public connect(): Promise<OT.Session> {
    return new Promise((resolve, reject) => {
      this.session.connect(this.token, (error: OT.OTError) => {
        error ? reject(error) : resolve(this.session);
      });
    });
  }

  public handleError(error: OT.OTError) {
    if (error) {
      console.log("Error from opentok. Below are the error details");
      console.log(error);
      this.globalService.publisherActive = false;
      setTimeout(() => {
        this.globalService.publisherActive = true;
      });
    }
  }
}
