import { Room } from './room';

export class User {
  id: string;
  name: string;
  email: string;
  username: string;
  rooms: Room[];
  breakoutRoom: string;
  breakoutName: string;
  openTokConnectionId:string;
  breakoutDeadline: number;
  breakoutToken: string;

  city: string;
  company: string;
  country: string;
  favoriteUserIds: string;
  iPAddresses: string;
  phone: string;
  status: number;
  stripeCardId: string;
  stripeCustomerId: string;

  isBanned: boolean;
  isDeleted: boolean;

  createdOn: string; // Date
  updatedOn: string; // Date
  bannedOn: string; // Date
  deletedOn: string; // Date
  inviteStatus: string; // Date
}
