import { Injectable, EventEmitter } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DialogService {
  public dialogs: Record<string, boolean> = {};
  public changes$: EventEmitter<Record<string, boolean>> = new EventEmitter();

  constructor() {}

  public isOpen(name: string): boolean {
    return this.dialogs[name];
  }
  
  public toggle(name: string) {
    console.log('name' + name);
    const state = this.dialogs[name];
    this.closeAll();
    this.dialogs[name] = !state;
    this.changes$.emit(this.dialogs);
  }

  public open(name: string) {
    this.closeAll();
    this.dialogs[name] = true;
    this.changes$.emit(this.dialogs);
  }

  public close(name: string) {
    this.dialogs[name] = false;
    this.changes$.emit(this.dialogs);
  }

  public closeAll() {
    for (const key in this.dialogs) {
      this.dialogs[key] = false;
    }
    this.changes$.emit(this.dialogs);
  }
}
