import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, MonoTypeOperatorFunction, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User, ApiResponse } from '@models/index';
import { CookieService } from 'ngx-cookie-service';

export interface SigninRequest {
  room: string;
  email: string;
}
export interface SignupRequest {
  room: string;
  email: string;
  name: string;
}
export interface ConfirmRequest {
  room: string;
  email: string;
  code: string;
}

@Injectable({ providedIn: 'root' })
export class UserApiService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookie: CookieService) { }

  public get(): Observable<User> {
    return this.http
      .post(environment.apiUrl + 'user/get', null)
      .pipe(map((response) => response as User), catchError((err) => {
        this.cookie.deleteAll();
        this.router.navigate(['/']);
        console.clear();
        return new Observable<User>();
      }));
  }

  public signin(payload: SigninRequest): Observable<boolean> {
    const fromData = new FormData();
    fromData.append('email', payload.email);

    return this.http
      .post(environment.apiUrl + 'user/signin', fromData)
      .pipe(this.handleResponse<boolean>());
  }

  public signup(payload: SignupRequest): Observable<boolean> {
    const fromData = new FormData();
    fromData.append('room', payload.room);
    fromData.append('email', payload.email);
    fromData.append('name', payload.name);

    return this.http
      .post(environment.apiUrl + 'user/signup', fromData)
      .pipe(this.handleResponse<boolean>());
  }

  public confirm(payload: ConfirmRequest): Promise<any> {
    const fromData = new FormData();
    fromData.append('room', payload.room);
    fromData.append('email', payload.email);
    fromData.append('code', payload.code);

    return this.http
      .post(environment.apiUrl + 'user/confirm', fromData).toPromise();
  }

  public autosign(email: string, code: string): Observable<string> {
    const fromData = new FormData();
    fromData.append('email', email);
    fromData.append('code', code);

    return this.http
      .post(environment.apiUrl + 'user/autosign', fromData)
      .pipe(this.handleResponse<string>());
  }

  public supersign(email: string, code: string): Observable<string> {
    const fromData = new FormData();
    fromData.append('email', email);
    fromData.append('code', code);

    return this.http
      .post(environment.apiUrl + 'user/supersign', fromData)
      .pipe(this.handleResponse<string>());
  }

  public autoSignup(email: string, name: string, roomName: string, tag: string): Observable<string> {
    const fromData = new FormData();
    fromData.append('email', email);
    fromData.append('name', name);
    fromData.append('roomName', roomName);
    fromData.append('tag', tag);

    return this.http
      .post(environment.apiUrl + 'user/autosignup', fromData)
      .pipe(this.handleResponse<string>());
  }

  public anonymousSignUp(name: string, email: string): Observable<string> {
    const fromData = new FormData();
    fromData.append('name', name);
    fromData.append('email', email);

    return this.http
      .post(environment.apiUrl + 'user/anonymous-signup', fromData)
      .pipe(this.handleResponse<string>());
  }

  public updateName(name: string): Observable<string> {
    const fromData = new FormData();
    fromData.append('name', name);

    return this.http
      .post(environment.apiUrl + 'user/update-name', fromData)
      .pipe(this.handleResponse<string>());
  }

  public update(name: string, room: string, email: string): Observable<any> {
    const fromData = new FormData();
    fromData.append('room', room);
    fromData.append('email', email);
    fromData.append('name', name);

    return this.http
      .post(environment.apiUrl + 'user/update', fromData)
      .pipe(this.handleResponse<string>());
  }

  // reusable custom operator
  private handleResponse<T>(): MonoTypeOperatorFunction<T> {
    return (input$) =>
      input$.pipe(map((response) => this.mapResponse(response as any)));
  }

  private mapResponse<T>(response: ApiResponse<T>): T {
    if (response.errorMessage) {
      throw response.errorMessage;
    }

    return response.value;
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

}
