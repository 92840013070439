<div class="text-center pd-t-100">
  <div class="d-flex justify-content-center">
    <div class="mx-wd-600">
      <img src="/assets/images/nocammic.png" class="img-fluid" alt="" />
    </div>
  </div>
  <h2>
    Follow these steps to grant permission to your camera/microphone.
  </h2>
  <div>
    <ul>
      <li>Go to the URL bar, where your page link is listed (ex: https://oneclick.chat/yoga), and click on the gray lock
        icon to its left . A box will drop down. Make sure that next to both Camera and Microphone, it says “Allow”.
      </li>
      <li>
        If Step 1 does not work, check whether any other applications are open that are using your camera or microphone
        (such as Zoom). If so, close out all applications.
      </li>
      <li>
        If the problem persists, please restart the computer to reset your camera and microphone settings.
      </li>
    </ul>
  </div>
  <div>
    <button type="button" class="btn btn-primary" (click)="backToRoom()">Go Back</button>
  </div>
</div>