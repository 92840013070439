import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CMSApiService {
  constructor(private http: HttpClient) { }

  url = environment.cmsURL; //'https://cms.oneclick.chat/api/content/oneclick';

  getToken(): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('grant_type', 'client_credentials');
    formData.append('client_id', 'oneclick:read');
    formData.append(
      'client_secret',
      'spcqsbfuobrc7l4f9l5xjg2ogi6hdsxjrn36bizrx5mx'
    );
    formData.append('scope', 'squidex-api');

    const url = 'https://cms.oneclick.chat/identity-server/connect/token';

    return this.http.post(url, formData).pipe(map((response: any) => response));
  }

  getSupport(): Observable<any> {
    const url = this.url + '/support/';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getFaq(): Observable<any> {
    const url = this.url + '/faq?$orderby=created asc';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getTeam(): Observable<any> {
    const url = this.url + '/team';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getPrivacy(): Observable<any> {
    const url = this.url + '/privacy';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getPress(): Observable<any> {
    const url = this.url + '/press';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getAbout(): Observable<any> {
    const url = this.url + '/about';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getBlog(): Observable<any> {
    const url = this.url + '/blog';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getBlogItem(id: string): Observable<any> {
    const url = this.url + '/blog/' + id;

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getTerms(): Observable<any> {
    const url = this.url + '/terms';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getHelp(): Observable<any> {
    const url = this.url + '/help';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getHelpEvents(): Observable<any> {
    const url = this.url + '/helpev';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getEvents(token: string): Observable<any> {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    const url = this.url + '/events?$filter=data/schedule/iv gt ' + yyyy + '-' + mm + '-' + dd + ' &$orderby=data/schedule/iv asc';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }
}
