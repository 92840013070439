import { Injectable } from '@angular/core';
import { Subject, Observable } from "rxjs";
import { BehaviorSubject } from 'rxjs';
import { Notification } from '@models/index';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  public last$ = new Subject<Notification>();

  public success = (message: string) => this.show(message, { type: 'success' });
  public info = (message: string) => this.show(message, { type: 'info' });
  public warn = (message: string) => this.show(message, { type: 'warn' });
  public error = (message: string) => this.show(message, { type: 'error' });

  public show(message: string, data?: Partial<Notification>) {
    console.log(data);
    const last = { id: Date.now().toString(), message, ...data };
    this.last$.next(last);
  }
  getObservable(): Observable<Notification> {
    return this.last$.asObservable();
  }
}
