<app-header></app-header>
<div class="content">
  <div class="container">
    <div *ngIf="isLoading">
      <div class="tx-center tx-14 tx-semibold mg-t-0 tx-color-03 tx-uppercase mg-t-30">Loading Events...</div>
      <div class="tx-center mg-t-10 mg-b-40">
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

    <div *ngIf="!isLoading">
      <div class="sub-header">
        <div class="title">
          <span *ngIf="whiteLabel != 'lab'">Our Events</span>
          <span *ngIf="whiteLabel == 'lab'">Illinois Events Calendar</span>
          <div class="secondary" *ngIf="whiteLabel == 'lab'">
            "Click "Reserve Your Spot" to sign up for any events you are interested at a time that is convenient to you.
          </div>
        </div>
        <div class="selector">
          <select [(ngModel)]="currentWeek" (change)="filter()">
            <option value="0">&nbsp; Select Week &nbsp;</option>
            <option value="{{this.weeks[0]}}" *ngIf="this.weeks.length > 0">&nbsp; This Week &nbsp;</option>
            <option value="{{this.weeks[1]}}" *ngIf="this.weeks.length > 1">&nbsp; Next Week &nbsp;</option>
            <option value="{{this.weeks[2]}}" *ngIf="this.weeks.length > 2">&nbsp; Following Week &nbsp;</option>
            <option value="{{this.weeks[3]}}" *ngIf="this.weeks.length > 3">&nbsp; In 3 Weeks &nbsp;</option>
          </select>
        </div>
      </div>
      <div class="events">
        <div *ngFor="let event of content">
          <div *ngIf="event.status != 'Expired'">
            <div class="event">
              <div class="image" *ngIf="event.data.Image">
                <div [ngClass]="{'date-full': whiteLabel !== 'lab', 'date-full-opaque' : whiteLabel === 'lab' }"
                  (click)="open(event, reserve)">
                  <div class="month">{{getDateFormatted(event.data.schedule.iv, 'MMMM d') }} </div>
                  <div>{{getDateFormatted(event.data.schedule.iv, 'EEEE') }}</div>
                  <div>{{getDateFormatted(event.data.schedule.iv, 'h:mm a') }}</div>
                  <div *ngIf="false">|{{currentWeek}}-{{event.week}}|</div>
                </div>
                <img src="{{cmsURLAssets}}/{{event.data.Image.iv}}">
              </div>
              <div class="information">
                <div class="title"></div>
                <div class="title" *ngIf="event.data.title">{{event.data.title.iv}}</div>
                <div class="description" *ngIf="event.data.description" [innerHTML]="event.data.description.iv"></div>

                <div class="waiver" *ngIf="event.data.hasWaiver && event.data.hasWaiver.iv">
                  <div class="collapsed">
                    <span *ngIf="!isNow(event)">By clicking on "Reserve Your Spot", I accept the exercise liability
                      waiver. </span>
                    <span *ngIf="isNow(event)">By clicking on "Join Now", I accept the exercise liability waiver.
                    </span>
                    <span class="underline" (click)="expand(event)">View waiver</span>
                  </div>
                  <div class="expanded" *ngIf="event.expanded">
                    <strong>Waiver: </strong>
                    {{event.data.waiver.iv}}
                  </div>
                </div>
                <span *ngIf="!isNow(event)">
                  <button class="btn btn-block btn-primary btn-uppercase mg-t-30 mg-l-5"
                    *ngIf="!hasReserved(event) && !hasContact" (click)="open(event, reserve)">RESERVE YOUR SPOT</button>
                  <button class="btn btn-block btn-primary btn-uppercase mg-t-30 mg-l-5"
                    *ngIf="!hasReserved(event) && hasContact" (click)="reserveWithEvent(event)">RESERVE YOUR
                    SPOT</button>
                  <button class="btn btn-block btn-secondary btn-uppercase mg-t-30 mg-l-5" *ngIf="hasReserved(event)"
                    (click)="cancelEvent(event)">SPOT RESERVED</button>
                </span>
                <span *ngIf="isNow(event)">
                  <button class="btn btn-block btn-secondary btn-uppercase mg-t-30 mg-l-5" (click)="joinNow(event)">JOIN
                    NOW!</button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #reserve let-modal>
  <div class="modal-body">
    <div class="form-row">
      <div class="form-group col-md-12">
        <div class="tx-center tx-uppercase tx-bold tx-32">{{currentEvent.title.iv}}</div>
        <div class="tx-center tx-uppercase tx-semibold tx-18">{{getDate(currentEvent.schedule.iv) }}</div>
        <div class="tx-center">
          <div class="tx-14">
            Fill out this form to reserve your spot for this event. You only need to fill out this information the first
            time you RSVP for an event.
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="" style="width:100%;">
        <input type="text" class="form-control input-control" placeholder="Type your first and last name"
          [(ngModel)]="contact.name">
        <input type="text" class="form-control input-control" placeholder="Type your email address"
          [(ngModel)]="contact.email">
        <select class="custom-select" [(ngModel)]="contact.slo" *ngIf="whiteLabel != 'lab'">
          <option selected value="">How did you find out about us?</option>
          <option value="Mary_Woods">Mary's Woods, OR</option>
          <option value="Western_Home">Western Home, IA</option>
          <option value="Brecon_Village">Brecon Village, MI</option>
          <option value="Bethany_Life">Bethany Life, IA</option>
          <option value="Woodhaven_Retirement_Communities">Woodhaven Retirement Communities, MI</option>
          <option value="Clark_Lindsey">Clark Lindsey, IL</option>
          <option value="Glacier_Hills">Glacier Hills, MI</option>
          <option value="Immanuel">Immanuel, NE</option>
          <option value="SeniorNet">SeniorNet</option>
          <option value="Music_for_Seniors">Music for Seniors</option>
          <option value="Facebook">Facebook</option>
          <option value="Instagram">Instagram</option>
          <option value="Meetup">Meetup</option>
          <option value="Eventbrite">Eventbrite</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div *ngIf="errorMessage" class="error-message">
        {{errorMessage}}
      </div>
    </div>
    <div class="form-row">
      <div class="mg-l-auto mg-r-auto mg-t-20">
        <button type="button" class="btn btn-cancel btn-secondary tx-11 tx-semibold tx-uppercase mg-r-10"
          (click)="cancelWindow()">Cancel</button>
        <button type="button" class="btn btn-ok btn-primary tx-11 tx-semibold tx-uppercase mg-l-10"
          (click)="ok()">OK</button>
      </div>
    </div>
  </div>
</ng-template>