import {
  Component, OnInit, OnDestroy, ViewChild,
  ElementRef,
} from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { CurrentService } from '@services/current.service';
import { debug } from 'logrocket';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css'],
  providers: [AuthService],
})
export class SigninComponent implements OnInit, OnDestroy {
  @ViewChild('publisherDiv', { static: true })
  public publisherDiv: ElementRef<HTMLVideoElement>;

  userExists = false;
  email = '';
  companyName;
  isCameraInitalized = false;
  companyLongName;
  signinform: FormGroup;
  submitted = false;
  adBlockEnabled = false;
  public loadingVideo;
  public videoSrc;

  constructor(
    public auth: AuthService,
    private current: CurrentService,
    private formBuilder: FormBuilder
  ) { }

  ngOnDestroy() { }

  ngOnInit() {
    this.companyName = environment.companyName;
    this.companyLongName = environment.companyLongName;


    if (this.current.token) {
      this.current.logoutWithRefresh();
    }

    this.signinform = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      code: ['', [Validators.required, Validators.minLength(6)]],
    });
    this.detectAdBlock()
    this.loadingVideo = true;
    this.getMediaStream()
      .then((stream) => {
        this.loadingVideo = false;
        this.isCameraInitalized = true;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async detectAdBlock() {
    const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
    try {
      await fetch(new Request(googleAdUrl)).catch(_ => this.adBlockEnabled = true)
    } catch (e) {
      this.adBlockEnabled = true
    } finally {
      console.log(`AdBlock Enabled: ${this.adBlockEnabled}`)
    }
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid

    if (this.auth.codeSent) {
      if (this.signinform.controls.code.errors === null) {
        let payload = {
          email: this.signinform.controls.email.value,
          code: this.signinform.controls.code.value,
        }
        this.auth.confirm(payload);
      } else {
        return;
      }
    } else {
      if (this.signinform.controls.email.errors === null) {
        let payload = {
          email: this.signinform.controls.email.value
        }
        this.auth.signin(payload);
      } else {
        return;
      }
    }
  }

  cancel() {
    this.auth.codeSent = false;
    this.auth.loading = false;
  }

  private getMediaStream(): Promise<MediaStream> {

    const video_constraints = { video: true };
    const _video = this.publisherDiv.nativeElement;
    return new Promise<MediaStream>((resolve, reject) => {
      // (get the stream)
      return navigator.mediaDevices.
        getUserMedia(video_constraints)
        .then(stream => {
          (<any>window).stream = stream; // make variable available to browser console
          _video.srcObject = stream;
          // _video.src = window.URL.createObjectURL(stream);
          _video.onloadedmetadata = function (e: any) { };
          _video.play();
          return resolve(stream);
        })
        .catch(err => reject(err));
    });
  }
  

  // convenience getter for easy access to form fields
  get f() { return this.signinform.controls; }

}
