import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentService } from '@services/current.service';
import { CookieService } from 'ngx-cookie-service';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isAuthenticated = false;
  isEvents = false;
  companyLongName;
  companyName;
  logoDark;
  isAnonymouse = false;
  backgroundColor;
  homepageUrl;
  whiteLabel;

  
  constructor(
    private current: CurrentService,
    public dialog: DialogService,
    private router: Router,
    private cookieService: CookieService,
  ) { }

  ngOnInit() {
    this.companyLongName = environment.companyLongName;
    this.companyName = environment.companyName;
    this.logoDark = environment.logoDark;
    this.backgroundColor = environment.headerBackgroundColor;
    this.homepageUrl = environment.homepageUrl;
    this.whiteLabel = environment.whiteLabel;

    if (this.current.token) {
      this.isAuthenticated = true;
    }

    const anonymous = this.cookieService.get('anonymous');
    if (anonymous && anonymous === '1') {
      this.isAuthenticated = false;
    }

    if (this.router.url === '/events') {
      this.isEvents = true;
    }
  }

}
