import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Contact } from '@models/index';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class FaqApiService {
  constructor(private http: HttpClient) {}

  public getQuestions(roomId: string): Observable<any[]> {
    const fromData = new FormData();
    fromData.append('roomId', roomId);

    return this.http
      .post(environment.apiUrl + 'qa/get-questions', fromData)
      .pipe(map((response) => response as []));
  }

  public getAnswers(roomId: string): Observable<any[]> {
    const fromData = new FormData();
    fromData.append('roomId', roomId);

    return this.http
      .post(environment.apiUrl + 'qa/get-answers', fromData)
      .pipe(map((response) => response as []));
  }

  public addQuestion(roomId: string, value: string, type: string): Observable<boolean> {
    const fromData = new FormData();
    fromData.append('roomId', roomId);
    fromData.append('value', value);
    fromData.append('type', type);


    return this.http
      .post(environment.apiUrl + 'qa/set-question', fromData)
      .pipe(map((response) => response as boolean));
  }

  public addAnswer(roomId: string, questionId: string, value: string, rating: string): Observable<boolean> {
    const fromData = new FormData();
    fromData.append('roomId', roomId);
    fromData.append('questionId', questionId);
    fromData.append('value', value);
    fromData.append('rating', rating);

    return this.http
      .post(environment.apiUrl + 'qa/set-answer', fromData)
      .pipe(map((response) => response as boolean));
  }

  public deleteQuestions(roomId: string, questionId: string): Observable<boolean> {
    const fromData = new FormData();
    fromData.append('roomId', roomId);
    fromData.append('questionId', questionId);

    return this.http
      .post(environment.apiUrl + 'qa/delete-question', fromData)
      .pipe(map((response) => response as boolean));
  }
}
