import {
  Component,
  ElementRef,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { PublisherService } from '../room/services/publisher.service';
import { Router } from '@angular/router';
import { CurrentService,  MixpanelService, NotificationService } from '@services/index';
import {OpentokService} from 'src/app/services/opentok.service';

@Component({
  selector: 'app-camera-initializer',
  templateUrl: './camera-initializer.component.html',
  styleUrls: ['./camera-initializer.component.css']
})
export class CameraInitializerComponent implements OnInit, OnDestroy {

  @Input() session: OT.Session;
  @Input() publishing: boolean;
  @Input() publisherMuted: boolean;
  @Output() cameraInitialized = new EventEmitter();
  @ViewChild('publisherDiv', { static: true })

  public publisherDiv: ElementRef<HTMLDivElement>;
  public loadingVideo: Boolean;
  constructor(
    private router: Router,
    public current: CurrentService,
    private opentok: OpentokService,
    public notificationService: NotificationService,
    public publisher: PublisherService
  ) {
    this.loadingVideo = true;
  }

  ngOnDestroy() {
    this.publisher.destroy();
  }

  ngOnInit() {
    if (sessionStorage.getItem("cameraChecked") !== "true") {
      this.opentok.testSession().then((res) => {
        if (res.success) {
          sessionStorage.setItem("cameraChecked", "true");
          this.publishCameraVideo();
          this.loadingVideo = false;
        } else {
          res.failedTests.forEach(error => {
            console.log(error);
            this.notificationService.error(error.error.message);
            //this.router.navigateByUrl('/permission');
          });
        }
      });
    } else {
      this.publishCameraVideo();
    }
  }

  publishCameraVideo() {
    this.publisher.initPublisher(
      this.session,
      this.publisherDiv.nativeElement
    );
    this.loadingVideo = false;
    this.cameraInitialized.emit(true);

  }

}
