<app-header></app-header>


<div *ngIf="questions && questions.length > 0">
  <div class="mid-box  pd-t-150" *ngIf="completed == false">
    <ng-container *ngFor="let q of questions">
      <div class="mb-5">
        <div class="question-text">
          <h4>{{q.value}}</h4>
        </div>
        <div *ngIf="q.type === 'text'">
          <textarea class="form-control text-area" [(ngModel)]="q.answer" rows="2"></textarea>
        </div>

        <div *ngIf="q.type === 'star'">
          <div class="favorite-div">
            <div class="row">
              <div class="col faviroutes-c text-right">
                <h4>Poor</h4>
              </div>
              <div class="col">
                <star-rating class="star-rate" [value]="q.rating" [totalstars]="5" [checkedcolor]="'#ea2b65'"
                  [uncheckedcolor]="'gray'" [size]="'50px'" [readonly]="disabled" (rate)="onRate($event, q)">
                </star-rating>
              </div>
              <div class="col  faviroutes-c text-left">
                <h4>Excellent</h4>
              </div>
            </div>

          </div>
        </div>

      </div>
    </ng-container>

    <div class="text-center pd-b-100">
      <button *ngIf="!loading" type="button" class="btn btn-primary" (click)="send()">SUBMIT</button>
    </div>
  </div>

  <div class="thank-you" *ngIf="completed == true">
    <h2>Thank you for your feedback</h2>
    <div>
      Your feedback is very important to us.<br>
      Someone from the our team will be back with you shortly.
    </div>

    <a routerLink="/home" *ngIf="frontendEnabled">Home</a>
    <a routerLink="/" *ngIf="!frontendEnabled">Home</a>
  </div>
</div>

<div *ngIf="questions && questions.length < 1">
  <div class="thank-you">
    <h2>The meeting has ended.</h2>
    <a routerLink="/home" *ngIf="frontendEnabled">Home</a>
    <a routerLink="/" *ngIf="!frontendEnabled">Home</a>
  </div>

</div>