import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  CurrentService,
  ApiService,
} from '@services/index';
import { PublisherService } from './publisher.service';
import { Role, Room, User } from '@models/index';
import { filter, tap } from 'rxjs/operators';
import { OpentokService } from 'src/app/services/opentok.service';


@Injectable({ providedIn: 'root' })
export class RoomService {
  public id: string;
  public name: string;
  public role: string;
  public knocks: any[];
  public handRaise: any[];
  public requests: any[];
  public participants: any[];
  public breakutUsers;
  public refreshParticipants = false;
  public session$ = new BehaviorSubject<OT.Session>(null);
  public streams$ = new BehaviorSubject<OT.Stream[]>([]);

  public isTroubleshooting = false;
  public isLocked = false;
  public isBreakoutActive = false;
  public retryStartSession = 0;
  public retryConnect = 0;
  constructor(
    private api: ApiService,
    private current: CurrentService,
    public publisher: PublisherService,
    private opentok: OpentokService
  ) { }

  public get connectionsCount(): number {
    return this.streams$.value.length + (this.isPublisher ? 1 : 0);
  }

  public get rowsCount(): number {
    const participants = this.streams$.value.length + (this.isPublisher ? 1 : 0);
    if (participants < 4) {
      return 1;
    }
    return 2;
  }

  public get unaceptedParticipantsCount(): number {
    return this.knocks && this.knocks.length ? this.knocks.length : 0;
  }

  public get handRaiseParticipantsCount(): number {
    return this.handRaise && this.handRaise.length ? this.handRaise.length : 0;
  }

  public get isModerator() {
    return this.role === Role.moderator;
  }
  public get isPublisher() {
    return this.role === Role.moderator || this.role === Role.publisher;
  }

  // TODO: change this code
  public get isRoomOwner() {
    if (!this.name) { return false; }
    if (!this.current.user) { return false; }
    if (!this.current.user.rooms) { return false; }
    if (!this.current.user.rooms.length) { return false; }

    return this.current.user.rooms.filter((room) => room.name === this.name).length > 0;
  }

  public createNewSession(): Promise<OT.Session> {
    return this.opentok.initSession(this.name, this.role);
  }

  public async startSession() {
    try {
      if (this.session$.value) {
        this.session$.value.off('streamCreated');
      }

      const session = await this.opentok.initSession(this.name, this.role);

      session.on('streamCreated', (event) => {
        console.log("Stream added " + event.stream.streamId);
        this.addStream(event.stream);
        if (event.stream.videoType === 'screen') {
          this.publisher.isSharingScreen$.next(true);
        }
      });
      session.on('streamDestroyed', (event) => {
        console.log("Stream removed " + event.stream.streamId);
        this.removeStream(event.stream);
        if (event.stream.videoType === 'screen') {
          this.publisher.isSharingScreen$.next(false);
          // this.api.room.update(sessionStorage.getItem('room-name'), { screenSharingId: '' });
        }
      });

      // session.on('connectionCreated', (event) => {
      //   if (window.location.href.indexOf('breakout') < 0 && event.connection.connectionId === event.target.connection.connectionId) {
      //     this.current.user.openTokConnectionId = event.connection.connectionId;
      //     // this.api.room.addActiveUser(sessionStorage.getItem('room-name'), this.current.user);
      //   }
      // });

      // session.on('connectionDestroyed', (event) => {
      //   console.log("removing stream here .... " + event.connection.connectionId);
      //   // if (!this.isBreakoutActive && this.isRoomOwner) {
      //   //   this.api.room.removeUserByConnectionId(sessionStorage.getItem('room-name'), event.connection.connectionId);
      //   // }
      // });

      this.session$.next(session);
    } catch (error) {
      // if (this.retryStartSession < 3) {
      //   this.retryStartSession = this.retryStartSession + 1;
      //   console.log("Retrying start session:" + this.retryStartSession);
      //   this.startSession();
      // } else {
      //   window.location.reload();
      // }
      console.log(error);
    }
  }


  public clearChat(roomName: string) {
    // this.api.messages.removeAll(roomName).then((m) => {
    //   this.api.messages.unseenMessageCount = 0;
    // });
  }

  public async startSessionById(sessionId: string) {
    try {
      if (this.session$.value) {
        this.session$.value.off('streamCreated');
      }

      const session = await this.opentok.initSessionById(sessionId);

      session.on('streamCreated', (event) => {
        console.log("Stream added " + event.stream.streamId);
        this.addStream(event.stream);
        if (event.stream.videoType === 'screen') {
          this.publisher.isSharingScreen$.next(true);
        }

      });
      session.on('streamDestroyed', (event) => {
        console.log("Stream removed " + event.stream.streamId);
        this.removeStream(event.stream);
        if (event.stream.videoType === 'screen') {
          this.publisher.isSharingScreen$.next(false);
          // this.api.room.update(sessionStorage.getItem('room-name'), { screenSharingId: '' });
        }
      });

      // session.on("connectionCreated", (event) => {
      //   if (window.location.href.indexOf('breakout') < 0 && event.connection.connectionId === event.target.connection.connectionId) {
      //     this.current.user.openTokConnectionId = event.connection.connectionId;
      //     // this.api.room.addActiveUser(sessionStorage.getItem('room-name'), this.current.user);
      //   }
      // });

      // session.on('connectionDestroyed', (event) => {
      //   console.log("removing stream here .... " + event.connection.connectionId);
      //   if (!this.isBreakoutActive && this.isRoomOwner) {
      //     this.api.room.removeUserByConnectionId(sessionStorage.getItem('room-name'), event.connection.connectionId);
      //   }
      // });

      this.session$.next(session);
    } catch (error) {
      // if (this.retryStartSession < 3) {
      //   this.retryStartSession = this.retryStartSession + 1;
      //   console.log("Retrying start session:" + this.retryStartSession);
      //   this.startSessionById(sessionId);
      // } else {
      //   window.location.reload();
      // }
      console.log(error);
    }
  }

  public async connect() {
    try {
      this.isTroubleshooting = false;
      await this.opentok.connect();
    } catch (error) {
      // if (this.retryConnect < 3) {
      //   this.retryConnect = this.retryConnect + 1;
      //   console.log("Retrying connecting:" + this.retryConnect);
      //   this.connect();
      // } else {
      //   window.location.reload();
      // }
      console.log(error);
    }
  }

  public async disconnect() {
    if (this.session$.value) {
      this.session$.value.disconnect();
    }
  }

  public toggleLock() {
    this.isLocked = !this.isLocked;
    this.fetchRoomInfo().subscribe((room) => {
      room.users.forEach((user) => this.api.room.accept(room.name, user));
      this.api.room.update(this.name, { isLockedFB: this.isLocked });
    });
  }

  public fetchRoomInfo() {
    const session = this.session$.value;

    if (!session) { return; }
    if (!session.sessionId) { return; }

    return this.api.room.getInfo(session.sessionId).pipe(
      tap((room: Room) => {
        if (room) {
          this.id = room.id;
          this.name = room.name;
          this.participants = room.users;
          this.current.room = room;
          this.api.room.update(room.name, room);
        }
      })
    );
  }

  public checkUserExists(userId: string) {
    const streams = this.streams$.value;
    return streams.filter((s) => { return s.name === userId }).length > 0;
  }

  private addStream(stream: OT.Stream) {
    // console.log("Adding stream here .... " + stream.videoType);
    // console.log("Adding stream here .... " + stream.connection.connectionId);
    const streams = this.streams$.value;
    const idx = streams.findIndex(
      (s) => s.connection.connectionId === stream.connection.connectionId && stream.videoType === s.videoType
    );
    if (idx > -1) {
      streams.splice(idx, 1);
    }

    this.streams$.next([...streams, stream]);

    this.fetchRoomInfo().subscribe((room: Room) => {
      if (!this.isRoomOwner) { return; }
      if (!room) { return; }
      if (!room.users) { return; }
      if (!room.users.length) { return; }

      const user: User = room.users.find(
        (user: User) => user.id === stream.name
      );
    });
  }

  private removeStream(stream: OT.Stream) {
    const streams = this.streams$.value;
    const idx = streams.indexOf(stream);

    if (idx > -1) {
      streams.splice(idx, 1);
    }

    this.streams$.next(streams);
    this.fetchRoomInfo().subscribe();
  }
}
