import { Component, OnInit } from '@angular/core';
import { DialogService } from '../../dialogs/dialog.service';
import { ApiService } from '@services/index';
import { NotificationService } from '@services/index';

@Component({
  selector: 'app-manage-banned',
  templateUrl: './manage-banned.component.html',
  styleUrls: ['./manage-banned.component.css']
})
export class ManageBannedComponent implements OnInit {

  allBanned;
  organization;
  loading = false;
  allOrganization;
  isAdmin = -1;

  constructor(
    public dialog: DialogService,
    public api: ApiService,
    public notificationService: NotificationService,
  ) { }

  ngOnInit(): void {

    this.loading = true;
    this.api.management.checkAdmin().subscribe((r: any) => {
      this.loading = false;
      if (r === true) {
        this.isAdmin = 1;
      } else {
        this.isAdmin = 0;
      }
    }, (error) => {
      this.isAdmin = 0;
      this.notificationService.error("Error calling API");
    });

    this.getList();

  }

  getList() {
    this.loading = true;

    this.api.management.getBanned("").subscribe((r:any) => {
      this.allBanned = r.value;
      this.loading = false;
    });
  }

  roomSuccess(args) {
    this.getList();
    this.dialog.closeAll();
  }

  unbanUser(id) {
    let r = confirm('Are you sure?');
    if (r === true) {
      this.api.management.unBan(id).subscribe((r: any) => {
        this.notificationService.success("UnBanned.");
        this.getList();
      });
    }

  }

}
