<app-header></app-header>
<div class="content">
  <div class="container">
    <div *ngIf="isLoading">
      <div class="tx-center tx-14 tx-semibold mg-t-0 tx-color-03 tx-uppercase mg-t-30">Loading faq...</div>
      <div class="tx-center mg-t-10 mg-b-40">
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!isLoading">
      <div *ngFor="let item of content">
        <div class="title" [innerHTML]="item.data.title.iv"></div>
        <div class="body" [innerHTML]="item.data.body.iv"></div>
      </div>
    </ng-container>
  </div>
</div>
