import { Injectable } from '@angular/core';
import { UserApiService } from './user-api.service';
import { RoomApiService } from './room-api.service';
import { BillingApiService } from './billing-api.service';
import { ContactApiService } from './contact-api.service';
import { FaqApiService } from './faq-api.service';
import { MessagesApiService } from './messages-api.service';
import { CMSApiService } from './cms-api.service';
import { ManagementApiService } from './management-api.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    public user: UserApiService,
    public room: RoomApiService,
    public contact: ContactApiService,
    public faq: FaqApiService,
    public billing: BillingApiService,
    public messages: MessagesApiService,
    public cms: CMSApiService,
    public management: ManagementApiService
  ) {}
}
