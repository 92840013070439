
export enum Role {
  publisher = 'publisher',
  subscriber = 'subscriber',
  moderator = 'moderator',
}

export enum RoomMode {
  room = 'room',
  event = 'event',
  private = 'private',
  universal = 'universal',
  visited = 'visited',
}

export class Room {
  id: string;
  name: string;
  description: string;
  mode: RoomMode;
  count: number;

  accesses: string;
  approvedUserIds: string;
  code: string;
  ownerUserId: string;
  bannedUserIds: string;
  service: string;
  sessionId: string;
  status: string;
  tags: string;
  templateId: string;
  users: Array<any>; // Array<User>;

  createdOn: string; // Date
  lastAccessed: string; // Date
  screenSharingId: string; // Date

  isFavorited: boolean;
  isDeleted: boolean;
  isLocked: boolean;
  isOpen: boolean;
  isRecordable: boolean;
  isTranscribable: boolean;
  // isScreenshared: boolean;
  eventStarted: boolean;

  isBreakoutActive: boolean;  
  ownerBreakoutDeadline: number;
  isLockedFB: boolean;
}
