import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(
    private http: HttpClient
  ) { }

  send(email: string, name: string, eventName: string, urlToEvent: string, dateTime: string, date: string, slo: string) {
    const formData: FormData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('eventName', eventName);
    formData.append('urlToEvent', urlToEvent);
    formData.append('dateTime', dateTime);
    formData.append('date', date);
    formData.append('slo', slo);

    const url = environment.apiUrl + 'schedule/send';

    return this.http
      .post(url, formData)
      .pipe(map((response: any) => response));
  }

  // tslint:disable-next-line: max-line-length
  sendWithUTM(email: string, name: string, eventName: string, urlToEvent: string, dateTime: string, date: string, slo: string, utm: string, campaignName: string, campaignDate: string) {
    const formData: FormData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('eventName', eventName);
    formData.append('urlToEvent', urlToEvent);
    formData.append('dateTime', dateTime);
    formData.append('date', date);
    formData.append('slo', slo);
    formData.append('utm', utm);
    formData.append('campaignName', campaignName);
    formData.append('campaignDate', campaignDate);

    const url = environment.apiUrl + 'schedule/send';

    return this.http
      .post(url, formData)
      .pipe(map((response: any) => response));
  }

  cancel(email: string, eventName: string, dateTime: string) {
    const formData: FormData = new FormData();
    formData.append('email', email);
    formData.append('eventName', eventName);
    formData.append('dateTime', dateTime);

    const url = environment.apiUrl + 'schedule/cancel';

    return this.http
      .post(url, formData)
      .pipe(map((response: any) => response));
  }

}
