<div class="feedback">
  <div class="feedback-question">Why?</div>
  <div>
    <textarea class="form-control" rows="4" placeholder="Feedback"></textarea>
  </div>
  <div>
    <button type="button" class="btn btn-primary" (click)="send()">SEND</button>
    <button type="button" class="btn btn-secondary" (click)="cancel()">CANCEL</button>
  </div>
</div>
