import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {

  showLogin = true;
  companyName;
  backgroundColor;
  logoDark;
  constructor() { }

  ngOnInit(): void {
    this.companyName = environment.companyName;
    this.showLogin = window.location.href.indexOf('bounce') < 0;
    this.backgroundColor =  environment.headerBackgroundColor;
    this.logoDark =  environment.logoDark;
  }

}
